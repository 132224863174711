import { Stack } from '@chakra-ui/react'

import { EvaluationFileField, TextareaField } from 'modules/common/components'

import { FilesTable } from './files-table'

export const OtherInfo = () => {
	const placeholder =
		'ex: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet sagittis elit. Nullam condimentum, enim quis hendrerit feugiat, quam erat consequat justo, vitae porta ipsum felis volutpat urna. Fusce suscipit eros lacus, id tristique nibh dictum sed. Aenean euismod libero'

	return (
		<Stack>
			<TextareaField
				label="Outras informações"
				fieldName="otherInfo"
				placeholder={placeholder}
				backgroundColor="white"
				minHeight={36}
				padding={8}
				labelProps={{ fontSize: 'xl' }}
			/>

			<Stack mt={4} spacing={4}>
				<EvaluationFileField fieldName="files" btnLabel="Adicionar um comprovante" />

				<FilesTable />
			</Stack>
		</Stack>
	)
}
