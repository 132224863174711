import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Stack, Box, Button, Link, Flex, Center } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { Actions } from 'modules/actions'
import { FullPageLayout, ProgressLine, TextGradient, StickyToast } from 'modules/common/components'
import { getPlanMea } from 'modules/education-plan/usecases'
import { Goals } from 'modules/goals'
import { getNationalGoals } from 'modules/goals/usecases'
import { Strategies } from 'modules/strategy'

export const GoalsStrategiesActions = () => {
	const navigate = useNavigate()
	const { state: locationState } = useLocation()
	const [showToast, setShowToast] = useState(false)

	const setNationalGoals = useBoundStore((state) => state.setNationalGoals)
	const plan = useBoundStore((state) => state.plan)
	const setGoals = useBoundStore((state) => state.setGoals)
	const setStrategies = useBoundStore((state) => state.setStrategies)
	const setActions = useBoundStore((state) => state.setActions)

	const Child = ({ children }) => {
		return (
			<Box bg="gray.200" borderRadius="3xl" p={2} layerStyle="gradientBorderGrayBg">
				{children}
			</Box>
		)
	}

	const closeToast = () => {
		setShowToast(false)
		window.history.replaceState({}, document.title)
	}

	useEffect(() => {
		if (locationState) {
			setShowToast(true)

			const fetchData = async () => {
				const planMea = await getPlanMea(plan?.id)
				if (planMea) {
					const { metas_subnacionais, estrategias, acoes } = planMea

					const dataGoals = metas_subnacionais.sort(function (a, b) {
						return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
					})

					setGoals(dataGoals)
					const dataStrategy = estrategias.sort(function (a, b) {
						return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
					})
					setStrategies(dataStrategy)
					const dataAcoes = acoes.sort(function (a, b) {
						return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
					})
					setActions(dataAcoes)
				}
			}

			if (locationState.status === 'success') {
				fetchData()
			}
		}
	}, [])

	useEffect(() => {
		const fetchNationalGoals = async () => {
			const { results } = await getNationalGoals()
			if (results) {
				const dataGoals = results.sort(function (a, b) {
					return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
				})
				setNationalGoals(dataGoals)
			}
		}
		fetchNationalGoals()
	}, [])

	const state = {
		message: 'Plano de Educação enviado!',
		status: 'success',
	}

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto"></Link>
			<FullPageLayout my={10}>
				<ProgressLine />

				<Stack mt={8} direction="row" align="center" justify="space-between">
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Metas / Estratégias / Ações
					</TextGradient>
				</Stack>

				{locationState && showToast && (
					<Center mt={8}>
						<StickyToast info={locationState} closeToast={closeToast} />
					</Center>
				)}

				<Stack mt={8} spacing={10}>
					<Child>
						<Goals />
					</Child>
					<Child>
						<Strategies />
					</Child>
					<Child>
						<Actions />
					</Child>
				</Stack>

				<Flex mt={8} justify="end">
					<Button
						fontSize={18}
						fontWeight="medium"
						aria-label="Finalizar cadastro"
						variant="defaultHover"
						borderRadius={5}
						onClick={() => navigate('/plano-de-educacao', { state })}
					>
						Finalizar
					</Button>
				</Flex>
			</FullPageLayout>
		</>
	)
}
