import { Box, Image, Stack, Text } from '@chakra-ui/react'

import ilustracaoDados2 from 'modules/common/assets/images/ilustracaoDados2.png'
import { FullPageLayout, TextGradient } from 'modules/common/components'

import AccordionDicionary from '../components/AccordionDicionary'

const Dicionary = () => {
	return (
		<FullPageLayout>
			<Stack ml={12} mr={12} direction={['column']} justify="space-between">
				<Box py={8}>
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Dicionário de dados
					</TextGradient>
					<Stack direction={{ base: 'column', lg: 'row' }} align="center">
						<Text fontSize="2xl" aria-label="Dicionário de Dados">
							Bem-vindo à nossa seção de Dicionário de Dados. Aqui, você terá acesso a uma visão
							estruturada de como os dados estão organizados para cada meta do Plano Nacional de
							Educação, dentro do Conecta PNE. O intuito é fornecer uma compreensão transparente e
							detalhada das tabelas de dados, incluindo descrições de campos, tipos de dados e
							relações entre tabelas.
						</Text>
						<Image src={ilustracaoDados2} alt="Imagem ilustrada de pessoas" w={600} h={400} />
					</Stack>
					<AccordionDicionary />
				</Box>
			</Stack>
		</FullPageLayout>
	)
}
export default Dicionary
