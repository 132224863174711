import { Stack, Text } from '@chakra-ui/react'

import { FileField } from 'modules/common/components'

import { FilesTable } from './files-table'
export const UploadField = () => {
	return (
		<Stack>
			<Text fontSize="xl" fontWeight="bold">
				Anexar o Plano Municipal de Educação em PDF *
			</Text>
			<Text fontSize="md" lineHeight="base">
				Anexar a Lei do Plano Municipal de Educação sancionada pelo prefeito, possíveis decretos e
				emendas, e o anexo da lei contendo as metas e estratégias.
			</Text>

			<Stack mt={4} spacing={4}>
				<FileField fieldName="files" btnLabel="Buscar o arquivo no seu computador" />

				<FilesTable />
			</Stack>
		</Stack>
	)
}
