import { useEffect, useMemo, useState } from 'react'
import { AiFillClockCircle, AiFillLock, AiOutlineArrowUp } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import { BsFillPersonFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { Stack, Button, Icon, Link } from '@chakra-ui/react'

import { FullPageLayout, TextGradient } from 'modules/common/components'
import { TableWithPagination } from 'modules/common/components/CustomTable'

import { getProfiles as getProfile } from './usecases'

export const ProfileManagement = () => {
	const navigate = useNavigate()
	const [profiles, setProfiles] = useState([])

	const columns = useMemo(
		() => [
			{
				Header: 'NOME',
				accessor: 'nome',
				icon: <BsFillPersonFill />,
			},

			{
				Header: 'CPF',
				accessor: 'cpf',
				icon: <AiFillLock />,
			},
			{
				Header: 'PERFIL',
				accessor: 'perfil_do_usuario.nome',
				icon: <AiFillLock />,
			},
			{
				Header: 'STATUS',
				accessor: 'active',
				icon: <AiFillClockCircle />,
				iconR: <AiOutlineArrowUp />,
			},
		],
		[],
	)

	useEffect(() => {
		const loadProfiles = async () => {
			try {
				const { users } = await getProfile()
				setProfiles(users)
			} catch (err) {
				console.error('request error', err)
			}
		}
		loadProfiles()
	}, [])

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto"></Link>
			<FullPageLayout mb={10}>
				<Stack mt={8} mb={8} direction={['column', 'row']} align="center" justify="space-between">
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Gestão de Perfis
					</TextGradient>
					<Button
						p={6}
						fontSize="2xl"
						aria-label="Salvar"
						variant="defaultHover"
						fontWeight="medium"
						leftIcon={<Icon as={BiPlus} boxSize={4} />}
						onClick={() => navigate('./cadastrar')}
					>
						Adicionar Pessoa
					</Button>
				</Stack>

				{!!profiles.length && <TableWithPagination data={profiles} columns={columns} />}
			</FullPageLayout>
		</>
	)
}
