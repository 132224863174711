import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Step, StepIndicator, Stepper, useSteps, Flex, Box, Divider, Text } from '@chakra-ui/react'

import { TextGradient } from '..'

export const StepsLine = ({ steps }) => {
	const { pathname } = useLocation()
	const { activeStep, setActiveStep } = useSteps({
		index: 0,
		count: steps.length,
	})

	useEffect(() => {
		const index = steps.findIndex((step) => step.path === pathname)
		setActiveStep(index)
	}, [pathname])

	return (
		<Box position={'relative'} mt={8}>
			<Stepper index={activeStep} size="xs" alignItems={'center'} colorScheme={'esmeralda'}>
				{steps.map((step, index) => (
					<Step
						key={index}
						maxWidth={'fit-content'}
						display="flex"
						flexDir="column"
						gap={0}
						px={10}
					>
						<TextGradient
							fromColor="esmeralda.100"
							toColor="marinho.100"
							fontSize={'lg'}
							fontWeight={'bold'}
						>
							{step.title}
						</TextGradient>

						<StepIndicator />

						{activeStep === index ? (
							<Text color="esmeralda.100" fontSize={'lg'} fontWeight={'semibold'}>
								você está aqui
							</Text>
						) : (
							<Flex h={10}></Flex>
						)}
					</Step>
				))}
			</Stepper>
			<Divider
				border="1px #A8A1A1"
				bg="#A8A1A1"
				position="absolute"
				height="2.5px"
				width="full"
				top="47px"
				zIndex={-1}
			/>
		</Box>
	)
}
