export const createEvaluationFileSlice = (set) => ({
	evaluationFiles: [],
	setEvaluationFiles: (data) =>
		set((state) => ({
			evaluationFiles: [...state.files, ...data],
		})),
	removeFile: (file) =>
		set((state) => ({
			evaluationFiles: state.evaluationFiles.filter((element) => element !== file),
		})),
	resetFiles: () => set({ evaluationFiles: [] }),
})
