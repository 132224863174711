import { Navigate, Outlet } from 'react-router-dom'

import { userStore } from 'store/user'

import { useHasSession } from 'modules/common/hooks'

export const PrivateRoute = () => {
	const isAuthenticated = useHasSession()
	const user = userStore((state) => state.user)
	const clearTokens = userStore((state) => state.clearTokens)

	if (isAuthenticated && user?.termo_aceito) {
		return <Outlet />
	}

	if (!isAuthenticated) clearTokens()
	return <Navigate to="/" />
}
