import env from '@beam-australia/react-env'

import axios from 'axios'
import { userStore } from 'store/user'

const baseURL = env('API_URL')

export const refreshToken = async (refreshToken, keepRefresh) => {
	const clearTokens = userStore((state) => state.clearTokens)
	const setAccess = userStore((state) => state.setAccess)

	const refreshed = await axios
		.request({
			url: `${baseURL}/usuario/refresh/`,
			method: 'POST',
			data: { refresh: refreshToken },
		})
		.then((res) => {
			return res.data
		})

	clearTokens(keepRefresh)
	setAccess(refreshed)

	return refreshed
}
