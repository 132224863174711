import { useTable, usePagination } from 'react-table'

import { Table, useBreakpointValue } from '@chakra-ui/react'

import { DesktopTableContent, MobileTableContent } from 'modules/common/components/CustomTable'

export const TableWithPagination = ({
	data,
	columns,
	pagination,
	fetchData,
	currentPage,
	handleChange,
	handleChangeTrash,
	...rest
}) => {
	const mobileScreen = useBreakpointValue({ base: true, md: false })
	const ResponsiveContent = mobileScreen ? MobileTableContent : DesktopTableContent

	const { getTableProps, getTableBodyProps, prepareRow, page, headers } = useTable(
		{
			columns,
			data,
			manualPagination: true,
			pageCount: 1,
			autoResetPage: false,
			initialState: { pageIndex: currentPage },
		},
		usePagination,
	)

	return (
		<Table {...getTableProps()} variant="simple" {...rest}>
			<ResponsiveContent
				headers={headers}
				page={page}
				getTableBodyProps={getTableBodyProps}
				prepareRow={prepareRow}
				handleChange={handleChange}
				handleChangeTrash={handleChangeTrash}
			/>
		</Table>
	)
}
