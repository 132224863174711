import fetch from 'config/api'

export const updateTermo = async (id, data) => {
	return fetch({
		url: `/ta_resposta/${id}/`,
		method: 'PUT',
		data,
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('update termo error', err))
}
