import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AiFillExclamationCircle } from 'react-icons/ai'
import { IoCloseOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Button, Center, IconButton, Icon, Text, Flex } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBoundStore } from 'store'

import { FullPageLayout, ProgressLine, TextGradient, StickyToast } from 'modules/common/components'

import { Form } from './form'
import { uploadPlanFiles } from './helpers'
import { createPlan, updatePlan } from './usecases'
import { PlanValidation } from './validators'

export const EducationPlanForm = () => {
	const navigate = useNavigate()
	const validation = PlanValidation()
	const [showToast, setShowToast] = useState(false)
	const [showToast2, setShowToast2] = useState(false)
	const [formStatusToast, setFormStatusToast] = useState({ show: false, info: {} })

	const closeToast = () => {
		setShowToast(false)
		setShowToast2(false)
		window.history.replaceState({}, document.title)
	}

	const plan = useBoundStore((state) => state.plan)

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
		defaultValues: {
			lawNumber: plan?.numero_da_lei,
			initialDate: plan?.dt_inicio_vigencia,
			endingDate: plan?.dt_fim_vigencia,
			goalsQnt: plan?.qtd_metas,
			stratQnt: plan?.qtd_estrategias,
			evaluationPeriod: plan?.periodo_avaliacao_previsto,
			evaluationHappened: plan?.houve_avaliacao || false,
			evaluationYears: plan?.ano_avaliacao,
			legalActResponsible: plan?.responsavel,
			legalActDescription: plan?.descricao,
			legalActNumber: plan?.numero,
			legalActDate: plan?.data,
			files: plan?.arquivos,
		},
	})

	const { isSubmitSuccessful } = methods.formState

	const handleResponse = (response, updating = false) => {
		if (!response.status) {
			const planStatus = updating ? 'editado' : 'cadastrado'

			const state = {
				message: `Plano Municipal de Educação ${planStatus}!`,
				status: 'success',
				link: {
					show: true,
					action: 'Ir para o',
					destiny: 'cadastro de Metas/Estratégias/Ações.',
					to: '/metas-estrategias-acoes',
				},
			}
			return state
		}

		return response
	}

	const onSubmit = async (values) => {
		const {
			lawNumber,
			initialDate,
			endingDate,
			goalsQnt,
			stratQnt,
			evaluationPeriod,
			evaluationHappened,
			evaluationYears,
			legalActDescription,
			legalActNumber,
			legalActDate,
			legalActResponsible,
			files,
		} = values

		const formatDate = (dateObject) => {
			return dateObject.toISOString().split('T')[0]
		}

		const ano_avaliacao = evaluationYears.map((year) => {
			const value = year.value ? year.value : year
			return parseInt(value)
		})
		const responsavel = legalActResponsible.map((responsible) => responsible.value)
		const arquivos = await uploadPlanFiles(files)

		const payload = {
			numero_da_lei: lawNumber,
			dt_inicio_vigencia: formatDate(initialDate),
			dt_fim_vigencia: formatDate(endingDate),
			qtd_metas: goalsQnt,
			qtd_estrategias: stratQnt,
			periodo_avaliacao_previsto: evaluationPeriod,
			houve_avaliacao: evaluationHappened,
			ano_avaliacao,
			descricao: legalActDescription,
			numero: legalActNumber,
			data: formatDate(legalActDate),
			responsavel,
			arquivos,
		}

		if (plan) {
			try {
				const response = await updatePlan(plan.id, payload)
				if (response) {
					const updating = true
					const state = handleResponse(response, updating)
					setFormStatusToast({ show: true, info: state })
				}
			} catch (err) {
				console.error('GOT ERROR', err)
			}
		} else {
			try {
				const response = await createPlan(payload)
				if (response) {
					const state = handleResponse(response)
					setFormStatusToast({ show: true, info: state })
				}
			} catch (err) {
				console.error('GOT ERROR', err)
			}
		}
	}

	return (
		<FullPageLayout>
			<ProgressLine />

			<Stack mt={8} gap={4}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
				>
					Plano de Educação
				</TextGradient>

				{formStatusToast.show && (
					<Center mt={8}>
						<StickyToast info={formStatusToast.info} closeToast={closeToast} />
					</Center>
				)}

				{showToast && (
					<Center mt={8}>
						<Flex
							borderRadius="xl"
							bg="red"
							color="white"
							w="max-content"
							alignItems="center"
							gridGap={4}
							py={2}
							px={4}
						>
							<Icon as={AiFillExclamationCircle} boxSize={5} />
							<Flex gridGap={1}>
								<Text
									fontSize="sm"
									fontWeight="bold"
									style={{
										whiteSpace: 'normal',
										wordWrap: 'break-word',
									}}
								>
									Informe uma data final maior que a data inicial
								</Text>
							</Flex>
							<IconButton
								px={0}
								variant="ghost"
								_hover={{ bg: 'none' }}
								icon={<Icon as={IoCloseOutline} boxSize={5} fontWeight="bold" />}
								onClick={closeToast}
							/>
						</Flex>
					</Center>
				)}
				{showToast2 && (
					<Center mt={8}>
						<Flex
							borderRadius="xl"
							bg="red"
							color="white"
							w="max-content"
							alignItems="center"
							gridGap={4}
							py={2}
							px={4}
						>
							<Icon as={AiFillExclamationCircle} boxSize={5} />
							<Flex gridGap={1}>
								<Text
									fontSize="sm"
									fontWeight="bold"
									style={{
										whiteSpace: 'normal',
										wordWrap: 'break-word',
									}}
								>
									Informe uma data depois de 01/01/1999
								</Text>
							</Flex>
							<IconButton
								px={0}
								variant="ghost"
								_hover={{ bg: 'none' }}
								icon={<Icon as={IoCloseOutline} boxSize={5} fontWeight="bold" />}
								onClick={closeToast}
							/>
						</Flex>
					</Center>
				)}
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={4}>
							<Form />
						</Box>

						<Stack direction="row" justify="end" mt={7} mb={16}>
							<Button variant="cancel" fontWeight="semibold" onClick={() => navigate('./..')}>
								Voltar
							</Button>
							<Button
								type="submit"
								variant="defaultHover"
								fontWeight="semibold"
								isDisabled={isSubmitSuccessful}
							>
								Salvar
							</Button>
						</Stack>
					</form>
				</FormProvider>
			</Stack>
		</FullPageLayout>
	)
}
