import env from '@beam-australia/react-env'

import fetch from 'config/api'

export const authenticate = async (code) => {
	const host = env('PNE_HOST')
	const redirect_uri = `${host}/login`

	return fetch({
		url: '/govbr/',
		method: 'GET',
		params: {
			redirect_uri,
			code,
		},
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('auth error', err))
}
