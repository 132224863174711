import { useEffect, useMemo, useState } from 'react'
import { AiFillClockCircle, AiFillLock, AiOutlineArrowUp } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import { BsFillPersonFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { Stack, Button, Text, Icon, Link } from '@chakra-ui/react'

import { FullPageLayout, TextGradient, ProgressLine } from 'modules/common/components'
import { TableWithPagination } from 'modules/common/components/CustomTable'

import { getArticulators } from './usecases'

export const Articulators = () => {
	const limitOfArticulators = 10

	const navigate = useNavigate()
	const [articulators, setArticulators] = useState([])
	function formataCPF(cpf) {
		cpf = cpf.replace(/[^\d]/g, '')

		return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
	}
	useEffect(() => {
		const loadArticulators = async () => {
			try {
				const { results } = await getArticulators()
				const filteredArticulators = results.filter((profile) => profile.pflcod === '4')
				filteredArticulators?.forEach((element) => {
					element.cpf = formataCPF(element.cpf)
				})

				setArticulators(filteredArticulators)
			} catch (err) {
				console.error('request error', err)
			}
		}
		loadArticulators()
	}, [])

	const columns = useMemo(
		() => [
			{
				Header: 'NOME',
				accessor: 'nome',
				icon: <BsFillPersonFill />,
			},

			{
				Header: 'CPF',
				accessor: 'cpf',
				icon: <AiFillLock />,
			},
			{
				Header: 'PERFIL',
				accessor: 'pfldsc',
				icon: <AiFillLock />,
			},
			{
				Header: 'STATUS',
				accessor: 'status',
				icon: <AiFillClockCircle />,
				iconR: <AiOutlineArrowUp />,
			},
		],
		[],
	)

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto"></Link>
			<FullPageLayout mb={10}>
				<ProgressLine />

				<Stack mt={8} direction={['column', 'row']} align="center" justify="space-between">
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Articuladores
					</TextGradient>
					<Button
						p={6}
						fontSize="2xl"
						aria-label="Salvar"
						variant="defaultHover"
						fontWeight="medium"
						leftIcon={<Icon as={BiPlus} boxSize={4} />}
						onClick={() => navigate('./create')}
					>
						Adicionar Articulador(a)
					</Button>
				</Stack>

				<Text py={6}>
					Você pode adicionar até {limitOfArticulators} articuladores. Até o momento você tem{' '}
					{articulators ? articulators.length : 0} cadastrados.
				</Text>

				{!!articulators.length && <TableWithPagination data={articulators} columns={columns} />}

				<Stack>
					<Button
						mt={10}
						mb={24}
						fontSize="xl"
						fontWeight="medium"
						aria-label="Ir para cadastro de plano"
						variant="defaultHover"
						alignSelf="end"
						onClick={() => navigate('/plano-de-educacao')}
					>
						Ir para o cadastro de Plano de Educação
					</Button>
				</Stack>
			</FullPageLayout>
		</>
	)
}
