import { useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { FullPageLayout, StepsLine, TextGradient } from 'modules/common/components'

export const GenerateReport = () => {
	const navigate = useNavigate()
	const pdfUrl = useBoundStore((state) => state.pdfUrl)

	const steps = [
		{ title: 'Contextualização', path: '/formulario-avaliacao' },
		{ title: 'Metas e Indicadores', path: '/formulario-avaliacao/metas-e-indicadores' },
		{ title: 'Geração de Relatório', path: '/formulario-avaliacao/gerar-relatorio' },
	]

	return (
		<FullPageLayout>
			<StepsLine steps={steps} />
			<TextGradient
				fromColor="esmeralda.100"
				toColor="marinho.100"
				fontSize="5xl"
				fontWeight="bold"
				my={8}
			>
				Formulário de Avaliação
			</TextGradient>
			<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={8} mb={32}>
				<Text fontSize={'xl'} fontWeight={'bold'} mb={16}>
					Visualização prévia
				</Text>
				<Box bgColor={'white'} mb={28}>
					{pdfUrl && <iframe src={pdfUrl} width="100%" height="700px" />}
				</Box>
				<Text fontSize={'xl'}>Deseja enviar as informações e gerar o relatório?</Text>
				<Flex gap={4}>
					<Button variant={'defaultHover'} onClick={() => navigate('../metas-e-indicadores')}>
						Retornar
					</Button>
					<Button variant={'defaultHover'}>Gerar relatório</Button>
				</Flex>
			</Box>
		</FullPageLayout>
	)
}
