import { BiCaretDown, BiCaretUp } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	Text,
	Button,
	Icon,
	Stack,
} from '@chakra-ui/react'

import { userStore } from 'store/user'
import { useTheme } from 'theme'

export const UserActionsButton = () => {
	const navigate = useNavigate()
	const { isHighContrast } = useTheme()
	const user = userStore((state) => state.user)
	const clearUser = userStore((state) => state.clearUser)
	const clearTokens = userStore((state) => state.clearTokens)
	const userName = user?.nome?.split(' ')[0]

	const menuBg = isHighContrast ? '#353535' : '#fff'
	const menuItemHover = isHighContrast ? { bg: 'none' } : { bg: 'gray.200' }

	const handleLogout = () => {
		clearTokens()
		clearUser()
	}

	return (
		<Menu>
			{({ isOpen, onOpen }) => (
				<>
					<MenuButton
						as={Button}
						isActive={isOpen}
						rightIcon={
							isOpen ? (
								<Icon as={BiCaretUp} color="#3F9E6E" />
							) : (
								<Icon as={BiCaretDown} color="#3F9E6E" />
							)
						}
						bg="white"
						border="1px solid gray.200"
						borderRadius="lg"
						onMouseOver={onOpen}
					>
						<Stack direction="row" spacing={1}>
							<Text color="blue.900">Olá,</Text>
							<Text color="blue.900" fontWeight="semibold">
								{userName}
							</Text>
						</Stack>
					</MenuButton>
					<MenuList bg={menuBg}>
						<MenuItem
							_focus={{ bg: 'none' }}
							onClick={() => navigate('/home')}
							_hover={menuItemHover}
						>
							Perfil
						</MenuItem>
						<MenuDivider />
						<MenuItem onClick={() => navigate('/termo-adesao')} _hover={menuItemHover}>
							Termo de adesão
						</MenuItem>
						<MenuDivider />
						<MenuItem onClick={() => navigate('/gestao-de-perfis')} _hover={menuItemHover}>
							Gestão de perfis
						</MenuItem>
						<MenuDivider />
						<MenuItem onClick={handleLogout} _hover={menuItemHover}>
							Sair
						</MenuItem>
					</MenuList>
				</>
			)}
		</Menu>
	)
}
