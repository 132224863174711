export const Roles = {
	SUPER: 'SUPER ADMIN',
	MEC: 'MEC',
	GESTOR: 'GESTOR',
	ARTICULADOR: 'ARTICULADOR',
	MONITOR: 'MONITORAMENTO',
	AVALIADOR: 'AVALIAÇÃO',
}

export const Permissions = {
	'articulators.read': ['SUPER ADMIN', 'MEC', 'GESTOR'],
	'articulators.list': ['SUPER ADMIN', 'MEC', 'GESTOR'],
	'articulators.create': ['SUPER ADMIN', 'MEC', 'GESTOR'],
	'articulators.update': ['SUPER ADMIN', 'MEC', 'GESTOR'],
	'articulators.delete': ['SUPER ADMIN', 'MEC', 'GESTOR'],
	//
	'plan.read': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'plan.list': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'plan.create': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'plan.update': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'plan.delete': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'plan.evaluate': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR', 'AVALIAÇÃO'],
	//
	'goals.read': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'goals.list': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'goals.create': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'goals.update': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'goals.delete': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	//
	'strategies.read': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'strategies.list': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'strategies.create': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'strategies.update': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'strategies.delete': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	//
	'actions.read': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'actions.list': ['SUPER ADMIN', 'MEC', 'GESTOR', 'ARTICULADOR'],
	'actions.create': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'actions.update': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	'actions.delete': ['SUPER ADMIN', 'GESTOR', 'ARTICULADOR'],
	//
	'profile.read': ['SUPER ADMIN', 'MEC'],
	'profile.list': ['SUPER ADMIN', 'MEC'],
	'profile.create': ['SUPER ADMIN', 'MEC'],
	'profile.update': ['SUPER ADMIN', 'MEC'],
	'profile.delete': ['SUPER ADMIN', 'MEC'],
}

export const can = (permission) => {
	const acceptableRoles = Permissions[permission]
	return Roles.some((role) => acceptableRoles.includes(role))
}
