import { Box, Stack } from '@chakra-ui/react'

import { TextGradient } from 'modules/common/components'

const DataBar = () => {
	return (
		<Stack direction={'row'} mt={4}>
			<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={1.5}>
				<TextGradient fromColor="esmeralda.100" toColor="marinho.100" fontSize="1xl">
					Categorias de dados disponíveis
				</TextGradient>
			</Box>
			<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={1.5}>
				<TextGradient fromColor="esmeralda.100" toColor="marinho.100" fontSize="1xl">
					Metas e Indicadores
				</TextGradient>
			</Box>
			<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={1.5}>
				<TextGradient fromColor="esmeralda.100" toColor="marinho.100" fontSize="1xl">
					Microdados Públicos
				</TextGradient>
			</Box>
			<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={1.5}>
				<TextGradient fromColor="esmeralda.100" toColor="marinho.100" fontSize="1xl">
					Dúvidas Frequentes
				</TextGradient>
			</Box>
			<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={1.5}>
				<TextGradient fromColor="esmeralda.100" toColor="marinho.100" fontSize="1xl">
					Dados Conectados
				</TextGradient>
			</Box>
		</Stack>
	)
}
export default DataBar
