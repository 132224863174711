import { useMemo } from 'react'

import { useBoundStore } from 'store'

import { TableWithPagination } from 'modules/common/components/CustomTable'

import { getFile } from '../usecases'

export const FilesTable = () => {
	const files = useBoundStore((state) => state.files)

	const columns = useMemo(
		() => [
			{
				Header: 'ARQUIVO',
				accessor: 'nome',
			},
			{
				Header: 'TAMANHO',
				accessor: 'tamanho',
			},
			{
				Header: 'DATA DE ENVIO',
				accessor: 'data_de_envio',
			},
		],
		[],
	)

	if (files?.length === 0) return

	let tableData

	// eslint-disable-next-line no-prototype-builtins
	if (files.find((file) => file.hasOwnProperty('nome'))) {
		tableData = files
	} else {
		tableData = files.map(async (id) => {
			if (id) {
				const file = await getFile(id)
				return file
			}
		})
	}

	return <TableWithPagination data={tableData} columns={columns} bg="white" />
}
