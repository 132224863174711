import { useWatch } from 'react-hook-form'

import { Text } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { SelectCheckboxField } from 'modules/common/components'

import { Field, ResponsibleField } from '.'
import { evaluationYearsOptions } from '../helpers'

export const Evaluation = () => {
	const evaluationHappened = useWatch({
		name: 'evaluationHappened',
	})

	const plan = useBoundStore((state) => state.plan)

	const defaultValue = plan?.ano_avaliacao?.map((element) =>
		evaluationYearsOptions.find((option) => option.value === element?.toString()),
	)

	if (!evaluationHappened) {
		return (
			<Text lineHeight="base">
				Ao selecionar que o Município não tem avaliação do Plano de Educação, você não poderá
				avançar no preenchimento do sistema e seus benefícios.
			</Text>
		)
	}

	return (
		<>
			<Field label="Anos que ocorreram as avaliações *" textAsLabel>
				<SelectCheckboxField
					options={evaluationYearsOptions}
					defaultValue={defaultValue}
					fieldName="evaluationYears"
					placeholder="Selecione os anos em que ocorreram avaliações"
				/>
			</Field>

			<ResponsibleField />
		</>
	)
}
