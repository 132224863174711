import { useState } from 'react'

import { Text, Switch, Box } from '@chakra-ui/react'

import { useBoundStore } from 'store'

export const ToggleSwitch = ({ label, warning, children }) => {
	const plan = useBoundStore((state) => state.plan)
	const [show, setShow] = useState(!!plan)

	return (
		<>
			<Box display="flex" alignItems="center" gap={2}>
				<Text fontSize="xl" fontWeight="bold">
					{label}
				</Text>
				<Text>Não</Text>
				<Switch colorScheme="green" defaultChecked={show} onChange={() => setShow(!show)} />
				<Text>Sim</Text>
			</Box>
			{show ? <>{children}</> : <Text lineHeight="base">{warning}</Text>}
		</>
	)
}
