export const colors = {
	canario: {
		60: '#FFEF9F',
		80: '#FFE97F',
		100: '#FFE45F',
	},
	marinho: {
		60: '#666699',
		80: '#333377',
		100: '#000055',
	},
	esmeralda: {
		50: '#e3fbee',
		60: '#8CC5A8',
		80: '#65B18B',
		100: '#3F9E6E',
		200: '#a1dcbe',
		300: '#7dcca5',
		400: '#5bbe8c',
		500: '#3F9E6E',
		600: '#318058',
		700: '#205b3e',
		800: '#0f3824',
		900: '#001509',
	},
	gray: {
		80: '#F8F9FA',
		180: '#F5F5F5',
		200: '#E2E8F0',
		300: '#A3A3A3',
		400: '#A8A1A1',
		900: '#353535',
	},
	blue: {
		300: '#1351B4',
		900: '#2D3748',
	},
	green: {
		500: '#8AC919',
	},
}
