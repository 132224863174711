import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Image, useBreakpointValue } from '@chakra-ui/react'

import { ThemeContext } from 'theme'

import WhiteLogo from 'modules/common/assets/high-contrast-theme-images/conectapne-white.svg'
import Logo from 'modules/common/assets/images/conectapne-logo.svg'
import VerticalLogo from 'modules/common/assets/images/conectapne-vertical-logo.svg'
import WhiteVerticalLogo from 'modules/common/assets/images/conectapne-vertical-white.svg'

export const ResponsiveLogo = () => {
	const isMobile = useBreakpointValue({ base: true, sm: false, md: false, lg: false })
	const { isHighContrast } = useContext(ThemeContext)

	const logo = isHighContrast ? WhiteLogo : Logo

	const responsiveLogo = isHighContrast ? WhiteVerticalLogo : VerticalLogo

	if (isMobile) {
		return (
			<Link to="/">
				<Image src={responsiveLogo} alt="Logotipo da plataforma conecta pne" />
			</Link>
		)
	}

	return (
		<Link to="/">
			<Image src={logo} alt="Logotipo da plataforma conecta pne" />
		</Link>
	)
}
