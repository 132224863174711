/* eslint-disable react/jsx-key */
import { BsTrash } from 'react-icons/bs'

import { Tbody, Tr, Td, Text, HStack, Button, Box, Icon } from '@chakra-ui/react'

import { StatusBadge } from './status-badge'

export const MobileTableContent = ({ page, getTableBodyProps, prepareRow }) => {
	return (
		<Tbody {...getTableBodyProps()}>
			{page.map((row) => {
				prepareRow(row)
				return (
					<Tr {...row.getRowProps()}>
						<Td py={2.5} px={0}>
							{row.cells.map((cell) => {
								const statusColumn = cell.column.Header === 'STATUS'
								if (statusColumn) {
									return (
										<HStack
											{...cell.getCellProps()}
											w="full"
											justifyContent="space-between"
											alignItems="center"
											spacing={4}
										>
											<Text w="full" fontWeight="semibold">
												{cell.column.Header}
											</Text>
											<Box w="full">
												<StatusBadge value={cell.value} />
											</Box>
										</HStack>
									)
								}
								return (
									<HStack
										{...cell.getCellProps()}
										w="full"
										justifyContent="space-between"
										alignItems="center"
										spacing={4}
									>
										<Text w="full" fontWeight="semibold">
											{cell.column.Header}
										</Text>
										<Text w="full">{cell.value}</Text>
									</HStack>
								)
							})}
						</Td>
						<Td>
							<Button
								variant="ghost"
								_hover={{ background: 'none' }}
								fontSize="xl"
								onClick={() => alert('Esta funcionalidade está sendo desenvolvida.')}
							>
								<Icon as={BsTrash} color="marinho.80" boxSize={4} />
							</Button>
						</Td>
					</Tr>
				)
			})}
		</Tbody>
	)
}
