import { Stack, Center, Button, Text, useBreakpointValue } from '@chakra-ui/react'

import { FullPageLayout } from 'modules/common/components'

export const SectionsMenu = ({ aboutPneRef, aboutConectaPneRef, howItWorksRef, pneGoalsRef }) => {
	const responsiveFontsize = useBreakpointValue({
		base: 'lg',
		sm: '2xl',
		md: 'lg',
		lg: '3xl',
	})

	const responsiveWidth = useBreakpointValue({ base: 'xs', sm: 'md', md: 'xs', lg: 'xl' })

	const MenuItem = ({ children, sectionRef }) => {
		const handleScrollInto = () => {
			sectionRef.current.scrollIntoView({ behavior: 'smooth' })
		}

		return (
			<Button w={responsiveWidth} h={36} variant="gradient" onClick={handleScrollInto}>
				<Text fontWeight="bold" fontSize={responsiveFontsize}>
					{children}
				</Text>
			</Button>
		)
	}

	return (
		<Center>
			<FullPageLayout p={0}>
				<Stack py={16} spacing={4}>
					<Stack direction={['column', 'column', 'row']} alignItems="center" spacing={7}>
						<MenuItem sectionRef={aboutPneRef}>O que é o PNE?</MenuItem>
						<MenuItem sectionRef={aboutConectaPneRef}>O que é a plataforma Conecta PNE?</MenuItem>
					</Stack>
					<Stack direction={['column', 'column', 'row']} alignItems="center" spacing={7}>
						<MenuItem sectionRef={pneGoalsRef}>As 20 metas do PNE</MenuItem>
						<MenuItem sectionRef={howItWorksRef}>Como funciona a Conecta PNE?</MenuItem>
					</Stack>
				</Stack>
			</FullPageLayout>
		</Center>
	)
}
