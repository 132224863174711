import fetch from 'config/api'

export const uploadFile = async (fileName, file) => {
	return fetch({
		url: '/arquivo/upload/',
		method: 'POST',
		data: { nome: fileName, arquivo: file },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('upload error', err))
}
