import env from '@beam-australia/react-env'

import fetch from 'config/api'

const cpf = env('DEFAULT_CPF')
const password = env('DEFAULT_PASS')

export const getToken = async () => {
	return fetch({
		url: '/usuario/token/',
		method: 'POST',
		data: {
			cpf,
			password,
		},
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('auth error', err))
}
