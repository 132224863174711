import { useContext } from 'react'
import { IoMdContrast } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Stack,
	Link,
	Image,
	IconButton,
	Icon,
	useBreakpointValue,
	Button,
	Text,
} from '@chakra-ui/react'

import { FullPageLayout, DrawerMenu } from '..'

import { ThemeContext } from 'theme'
import { highContrastTheme } from 'theme/high-contrast-theme'

import GovbrLogo from 'modules/common/assets/images/govbr-logo.svg'
import { useHasSession } from 'modules/common/hooks'
import { govbrAuth } from 'modules/login/usecases'

export const Govbar = () => {
	return (
		<FullPageLayout boxShadow="xs">
			<Box py={10} display="flex" justifyContent="space-between">
				<Image src={GovbrLogo} alt="logotipo do governo brasileiro" w={114} h={41} />

				<NavigationMenu />
			</Box>
		</FullPageLayout>
	)
}

const NavigationMenu = () => {
	const mobileScreen = useBreakpointValue({ base: true, lg: false })

	if (mobileScreen) {
		return (
			<DrawerMenu placement="right">
				<MenuButtons direction="column" />
			</DrawerMenu>
		)
	}

	return <MenuButtons direction="row" />
}

const MenuButtons = ({ direction }) => {
	const { hostname } = window.location
	const navigate = useNavigate()

	const { isHighContrast, setTheme, resetTheme } = useContext(ThemeContext)

	const isAuthenticated = useHasSession()

	const handleLoginGovbr = async () => {
		if (hostname === 'localhost') {
			navigate('/login', { replace: true })
		} else {
			const url = await govbrAuth()
			window.location.href = url
		}
	}

	const handleHighContrast = () => {
		if (!isHighContrast) {
			setTheme(highContrastTheme)
		} else {
			resetTheme()
		}
	}

	const iconButtonColor = isHighContrast ? '#F4F4F4' : 'blue.300'

	return (
		<Stack direction={direction} alignItems="center" spacing={2.5}>
			<Link
				href="https://www.gov.br/pt-br/orgaos-do-governo"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: 'none' }}
				aria-label="Órgaõs do Governo"
			>
				Órgãos do Governo
			</Link>
			<Link
				href="https://www.gov.br/acessoainformacao/pt-br"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: 'none' }}
				aria-label="Acesso à Informação"
			>
				Acesso à Informação
			</Link>
			<Link
				href="http://www4.planalto.gov.br/legislacao/"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: 'none' }}
				aria-label="Legislação"
			>
				Legislação
			</Link>
			<Link
				href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: 'none' }}
				aria-label="Acessibilidade"
			>
				Acessibilidade
			</Link>
			<IconButton
				variant="ghost"
				_hover={{ bg: 'none' }}
				icon={<Icon as={IoMdContrast} />}
				color={iconButtonColor}
				cursor="pointer"
				aria-label="Liga e desliga alto contraste"
				onClick={handleHighContrast}
			/>
			{!isAuthenticated && (
				<Button
					bg="#1351B4"
					color="white"
					borderRadius="3xl"
					fontWeight="medium"
					_hover={{ bg: '#1351B4', color: 'white' }}
					aria-label="Entrar com gov.br"
					onClick={handleLoginGovbr}
				>
					<Stack direction="row" spacing={1}>
						<Text>Entrar com</Text>
						<Text fontWeight="bold">gov.br</Text>
					</Stack>
				</Button>
			)}
		</Stack>
	)
}
