import { useFormContext } from 'react-hook-form'

import { Stack, Text, Button } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { InputField, SelectField } from 'modules/common/components'

import { ImplementedGoalsTable } from './table'

export const ImplementedField = () => {
	const setImplementedGoals = useBoundStore((state) => state.setImplementedGoals)

	const { getValues } = useFormContext()
	const { implementedGoalYear, implementedGoalIndice } = getValues()

	const disableButton = !implementedGoalYear || !implementedGoalIndice

	const options = [
		{
			id: 1,
			title: '2024',
			value: 2024,
		},
	]

	const onClick = () => {
		const { implementedGoalYear, implementedGoalIndice } = getValues()

		if (implementedGoalYear && implementedGoalIndice) {
			const data = { implementedGoalYear, implementedGoalIndice }
			setImplementedGoals(data)
		}
	}

	return (
		<>
			<Stack spacing={4}>
				<Text fontSize={'xl'} fontWeight={'bold'}>
					Meta executada:
				</Text>
				<Stack direction="row" justifyContent={'space-between'} spacing={10}>
					<SelectField
						label={'Ano *'}
						fieldName={'implementedGoalYear'}
						placeholder={'Selecione um ano'}
						options={options}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}
						gap={'50%'}
						labelProps={{ fontSize: 'xl' }}
					/>
					<Button
						variant={'defaultHover'}
						width={'25%'}
						fontSize={'md'}
						fontWeight={'semibold'}
						onClick={onClick}
						disabled={disableButton}
					>
						+ Adicionar um ano
					</Button>
				</Stack>
				<InputField
					label={'Índice de atingimento executado no plano*'}
					placeholder={'Exemplo: 23,5%'}
					fieldName={'implementedGoalIndice'}
					display={'flex'}
					gap={20}
					labelProps={{ fontSize: 'xl ' }}
				/>
			</Stack>
			<ImplementedGoalsTable />
		</>
	)
}
