import { useFormContext, useFieldArray, useController } from 'react-hook-form'
import { IoCloseOutline } from 'react-icons/io5'

import {
	Button,
	Stack,
	Box,
	Text,
	Input,
	Textarea,
	Icon,
	FormLabel,
	Flex,
	StackDivider,
	FormControl,
	FormErrorMessage,
} from '@chakra-ui/react'

export const IndicatorFieldArray = () => {
	const fieldName = 'indicators'

	const shape = {
		numero: '',
		descricao: '',
	}

	const { control, register } = useFormContext()

	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName,
	})

	const {
		fieldState: { error },
	} = useController({
		name: fieldName,
	})

	return (
		<>
			<Stack direction={['column', 'row']} spacing={6}>
				<Text color="gray.900" fontSize="lg" fontWeight="bold">
					Indicadores (opcional)
				</Text>
				<Button variant="defaultHover" fontWeight="semibold" onClick={() => append(shape)}>
					+ Adicionar um indicador
				</Button>
			</Stack>

			<Stack spacing={4} divider={<StackDivider w={['full', '80%']} borderColor="gray.400" />}>
				{fields?.map((field, index) => (
					<Stack key={field.id} w={['100%', '80%']} spacing={5}>
						<Flex justify="space-between">
							<FormControl isInvalid={!!error?.[index]?.numero}>
								<Stack
									direction={['column', 'row']}
									gap={{ base: 0, md: 6 }}
									align={['normal', 'center']}
								>
									<FormLabel htmlFor="indicatorNumber" fontSize="md">
										Número*
									</FormLabel>
									<Box>
										<Input
											w={['full', 'fit-content']}
											bg="white"
											placeholder="Exemplo: 01.a"
											_placeholder={{
												fontWeight: 'normal',
												color: 'gray.300',
											}}
											{...register(`${fieldName}.${index}.numero`)}
										/>
										{error && (
											<FormErrorMessage>{error?.[index]?.numero?.message}</FormErrorMessage>
										)}
									</Box>
								</Stack>
							</FormControl>
							<Button px={0} bgColor="esmeralda.80" onClick={() => remove(index)}>
								<Icon as={IoCloseOutline} color="white" boxSize={5} />
							</Button>
						</Flex>

						<FormControl isInvalid={!!error?.[index]?.descricao}>
							<Flex gap={[0, 6]}>
								<FormLabel htmlFor="indicatorDescription" fontSize="md">
									Descrição*
								</FormLabel>
								<Box w="full">
									<Textarea
										bg="white"
										placeholder="Exemplo:  Ampliar a construção de creches."
										_placeholder={{
											fontWeight: 'normal',
											color: 'gray.300',
										}}
										{...register(`${fieldName}.${index}.descricao`)}
									/>
									{error && (
										<FormErrorMessage>{error?.[index]?.descricao?.message}</FormErrorMessage>
									)}
								</Box>
							</Flex>
						</FormControl>
					</Stack>
				))}
			</Stack>
		</>
	)
}
