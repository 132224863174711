import { useWatch } from 'react-hook-form'

import { InputField } from 'modules/common/components'

import { planValidity } from '../helpers'

export const EndingDate = () => {
	const initialDate = useWatch({
		name: 'initialDate',
	})

	return (
		<InputField
			label="Data fim de vigência*"
			fieldName="endingDate"
			inputType="date"
			placeholder="Ex: 02/07/2015"
			display="flex"
			alignItems="center"
			inputProps={{ min: initialDate || planValidity.min, max: planValidity.max }}
		/>
	)
}
