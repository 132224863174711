import { useMemo, useState, useCallback } from 'react'

import { Flex, Box, Text } from '@chakra-ui/react'

import fetch from 'config/api'
import { useBoundStore } from 'store'

import { GSATable } from 'modules/goals-strategies-actions/components'

export const List = () => {
	const actions = useBoundStore((state) => state.actions)
	const [page, setPage] = useState(0)

	const pageSlice = page === 0 ? page : page * 10
	const tableData = actions.slice(pageSlice, (page + 1) * 10)

	const handleChangeTrashAction = async (item) => {
		await fetch({
			url: `/acao/${item}/`,
			method: 'DELETE',
		})
			.then((res) => {
				return res.data.numero
			})
			.catch((err) => console.error('error', err))
	}

	const columnsAction = useMemo(
		() => [
			{
				Header: 'Número Ação',
				accessor: 'numero',
			},
			{
				Header: 'Estratégia Associada',
				accessor: 'estrategia_id',
			},
			{
				Header: 'Descrição',
				accessor: 'descricao',
			},
			{
				Header: '',
				accessor: 'trash',
			},
		],
		[],
	)

	if (!actions.length)
		return (
			<Flex w="full" p={4} mt={10} bg="white" justify="center">
				<Text textAlign="center">Nenhuma ação cadastrada até o momento</Text>
			</Flex>
		)

	const pagination = {
		first: page === 0,
		last: (page + 1) * 10 >= actions.length,
		pageSize: 10,
		totalElements: actions.length,
	}

	const fetchData = useCallback((index) => {
		setPage(index)
	}, [])

	return (
		<Box mt={8} mb={6}>
			<GSATable
				data={tableData}
				columns={columnsAction}
				pagination={pagination}
				fetchData={fetchData}
				currentPage={page}
				bg="white"
				handleChangeTrash={handleChangeTrashAction}
			/>
		</Box>
	)
}
