import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const userStore = create(
	persist(
		(set) => ({
			user: {},
			setUser: (data) => set({ user: data }),
			clearUser: () => set({ user: {} }),
			accessToken: null,
			refreshToken: null,
			setAccess: (access) => set({ accessToken: access }),
			setTokens: (data) => set({ accessToken: data.access, refreshToken: data.refresh }),
			clearTokens: (keepRefresh = false) => {
				if (keepRefresh) {
					set({ accessToken: null })
				} else {
					set({ accessToken: null, refreshToken: null })
				}
			},
		}),
		{
			name: 'user-store',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
)
