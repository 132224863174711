import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Center, CircularProgress } from '@chakra-ui/react'

import { userStore } from 'store/user'

import { getUserInfo } from 'modules/user/usecases'

import { authenticate, getToken } from './usecases'

const LoginRedirect = () => {
	const navigate = useNavigate()
	const setUser = userStore((state) => state.setUser)
	const setTokens = userStore((state) => state.setTokens)

	const [searchParams] = useSearchParams()
	const code = searchParams.get('code')

	useEffect(() => {
		const handleAuthenticate = async () => {
			let tokens = null

			if (code) {
				tokens = await authenticate(code)
			} else {
				tokens = await getToken()
			}

			if (tokens) {
				setTokens(tokens)
				const userInfo = await getUserInfo()
				if (userInfo) setUser(userInfo)

				if (userInfo.termo_aceito) {
					navigate('/home', { replace: true })
				} else {
					navigate('/termo-adesao', { replace: true })
				}
			}
		}
		handleAuthenticate()
	}, [code, navigate, setUser])

	return (
		<Center height="100vh">
			<CircularProgress isIndeterminate color="esmeralda.100" />
		</Center>
	)
}

export default LoginRedirect
