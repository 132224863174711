import { object, array, string } from 'yup'

export const GoalsValidation = () =>
	object({
		goalNumber: string()
			.required('Campo obrigatório')
			.matches(/^[0-9]+$/, 'Preencha apenas com números'),
		goalDescription: string().required('Campo obrigatório'),
		nationalGoals: array()
			.required('Campo obrigatório')
			.test({
				test: (array) => array.length > 0,
				message: 'Selecione pelo menos 1 meta',
			}),
		indicators: array().of(
			object({
				numero: string().required('Campo obrigatório'),
				descricao: string().required('Campo obrigatório'),
			}),
		),
	})
