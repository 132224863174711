/* eslint-disable react/jsx-key */
import { BsTrash } from 'react-icons/bs'

import { Thead, Tbody, Tr, Th, Td, Button, Icon, Checkbox, HStack, Box } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { StatusBadge } from './status-badge'

export const DesktopTableContent = ({
	page,
	headers,
	getTableBodyProps,
	prepareRow,
	handleChange,
	handleChangeTrash,
}) => {
	const nationalGoals = useBoundStore((state) => state.nationalGoals)
	const strategies = useBoundStore((state) => state.strategies)

	return (
		<>
			<Thead>
				<Tr>
					{headers.map((column) => {
						return (
							<Th {...column.getHeaderProps()} bg="gray.100">
								<HStack spacing={2}>
									<Box>{column.icon ? column.icon : <></>}</Box>
									<Box>{column.render('Header')}</Box>
									<Box>{column.iconR ? column.iconR : <></>}</Box>
								</HStack>
							</Th>
						)
					})}
					<Th></Th>
				</Tr>
			</Thead>
			<Tbody {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row)
					return (
						<Tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								const statusColumn = cell.column.Header === 'STATUS'
								const checkboxColumn = cell.column.id === 'checkbox'
								const trashColumn = cell.column.id === 'trash'
								const nationalGoalColumn = cell.column.id === 'meta_nacional'
								const estrategiaColumn = cell.column.id === 'estrategia'
								const nameColumn = cell.column.id === 'nome'
								if (statusColumn) {
									return (
										<Td {...cell.getCellProps()}>
											<StatusBadge value={cell.value} />
										</Td>
									)
								}
								if (checkboxColumn) {
									return (
										<Td {...cell.getCellProps()}>
											<Checkbox onChange={(e) => handleChange(e)} value={cell.row.original.id} />
										</Td>
									)
								}
								if (trashColumn) {
									return (
										<Td {...cell.getCellProps()}>
											<Button
												variant="ghost"
												_hover={{ background: 'none' }}
												fontSize="xl"
												onClick={() => handleChangeTrash(cell.value)}
											>
												<Icon as={BsTrash} color="marinho.80" boxSize={4} />
											</Button>
										</Td>
									)
								}
								if (nationalGoalColumn) {
									const pneGoal = cell.value
										.map((item) => nationalGoals.find((element) => element.id === item)?.numero)
										.join(', ')
									const value = pneGoal.length > 1 ? `PNE metas: ${pneGoal}` : `PNE meta ${pneGoal}`
									return <Td {...cell.getCellProps()}>{value}</Td>
								}
								if (estrategiaColumn) {
									const strategyNumber = cell.value
										.map((item) => strategies.find((element) => element.id === item)?.numero)
										.join(', ')
									const value =
										strategies.length > 1
											? `Estratégias: ${strategyNumber}`
											: `Estratégia ${strategyNumber}`
									return <Td {...cell.getCellProps()}>{value}</Td>
								}
								if (nameColumn) {
									return (
										<Td {...cell.getCellProps()}>
											{cell.render('Cell')}
											<Box>{cell.row.original.email}</Box>
										</Td>
									)
								}
								return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
							})}
						</Tr>
					)
				})}
			</Tbody>
		</>
	)
}
