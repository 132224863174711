import graphic1 from 'modules/common/assets/images/graphic/graphic-1.png'
import graphic2 from 'modules/common/assets/images/graphic/graphic-2.png'
import graphic4 from 'modules/common/assets/images/graphic/graphic-4.png'

export const graphicList = [
	{
		id: 1,
		title: 'Gráfico de Mapa',
		description:
			'Gráfico que oferece visualização de atingimento da meta-indicador no mapa do Brasil ',
		src: graphic1,
		secao: 'sumario',
		plot: 'mapa',
		meta: 1,
		indicador: '1A',
		enabled: true,
	},
	{
		id: 2,
		title: 'Gráfico de Situação',
		description:
			'Gráfico de barra horizontal que mostra a situação da meta-indicador em relação ao esperado em porcentagem',
		src: graphic2,
		secao: 'sumario',
		plot: 'situacao',
		meta: 1,
		indicador: '1A',
		enabled: true,
	},
	{
		id: 4,
		title: 'Gráfico de Evolução',
		description:
			'Gráfico de linha mostrando a evolução da meta-indicador durante um período de tempo',
		src: graphic4,
		secao: 'sumario',
		plot: 'evolucao',
		meta: 1,
		indicador: '1A',
		enabled: true,
	},
]
