import fetch from 'config/api'

export const updateUser = async (data, cpf) => {
	return fetch({
		url: `/usuario/update/${cpf}`,
		method: 'PUT',
		data,
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('Não foi possível atualizar o usuário', err))
}
