/* eslint-disable indent */
import React, { useState } from 'react'

import { TextGradient } from '..'

import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

import { Box, Button, Stack, Text } from '@chakra-ui/react'

const ReadMore = ({ metas }) => {
	const metasIniciais = [metas[0], metas[1], metas[2]]
	const [isReadMore, setIsReadMore] = useState(true)
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore)
	}

	return (
		<Stack alignItems={'center'} spacing={6}>
			{isReadMore
				? metasIniciais.map((meta) => (
						<Box
							key={meta.title}
							h="fit-content"
							fontSize="2xl"
							borderRadius="3xl"
							layerStyle="gradientBorder"
							aria-label={meta.title}
						>
							<Stack
								pl={12}
								py={4}
								pr={8}
								direction={['column', 'row']}
								spacing={12}
								align="center"
							>
								<TextGradient
									fromColor="esmeralda.100"
									toColor="marinho.100"
									fontSize="3xl"
									fontWeight="bold"
								>
									{meta.title}
								</TextGradient>
								<Text fontSize="xl" aria-label={meta.text}>
									{meta.text}
								</Text>
							</Stack>
						</Box>
				  ))
				: metas.map((meta) => (
						<Box
							key={meta.title}
							w="full"
							h="fit-content"
							fontSize="2xl"
							borderRadius="3xl"
							layerStyle="gradientBorder"
							aria-label={meta.title}
						>
							<Stack
								pl={12}
								py={4}
								pr={8}
								direction={['column', 'row']}
								spacing={12}
								align="center"
							>
								<TextGradient
									fromColor="esmeralda.100"
									toColor="marinho.100"
									fontSize="3xl"
									fontWeight="bold"
								>
									{meta.title}
								</TextGradient>
								<Text fontSize="xl" aria-label={meta.text}>
									{meta.text}
								</Text>
							</Stack>
						</Box>
				  ))}
			<Button
				w={287}
				h={14}
				fontSize="2xl"
				variant="default"
				borderRadius="lg"
				onClick={toggleReadMore}
				leftIcon={isReadMore ? <AiOutlinePlus /> : <AiOutlineMinus />}
				aria-label={isReadMore ? 'Exibir mais metas' : 'Exibir menos metas'}
			>
				{isReadMore ? 'Exibir mais metas' : 'Exibir menos metas'}
			</Button>
		</Stack>
	)
}

export default ReadMore
