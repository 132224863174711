import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Stack, Button, Text, Link, Box, Center, CircularProgress } from '@chakra-ui/react'

import { useBoundStore } from 'store'
import { userStore } from 'store/user'

import { FullPageLayout, ProgressLine, StickyToast, TextGradient } from 'modules/common/components'

import { FilesTable } from './components'
import { getPlan, getPlanMea } from './usecases'

export const EducationPlanList = () => {
	const navigate = useNavigate()
	const { state: locationState } = useLocation()
	const [isLoading, setIsLoading] = useState(false)
	const [showToast, setShowToast] = useState(false)

	const user = userStore((state) => state.user)
	const isArticulator = user.pflcod === '0002'

	const plan = useBoundStore((state) => state.plan)
	const setPlan = useBoundStore((state) => state.setPlan)
	const setGoals = useBoundStore((state) => state.setGoals)
	const setStrategies = useBoundStore((state) => state.setStrategies)
	const setActions = useBoundStore((state) => state.setActions)
	const setFiles = useBoundStore((state) => state.setFiles)

	useEffect(() => {
		setIsLoading(true)
		const fetchPlan = async () => {
			const { results } = await getPlan()
			if (results) {
				const planMea = await getPlanMea(results[0].id)
				if (planMea) {
					const { plano_subnacional, metas_subnacionais, estrategias, acoes } = planMea
					setPlan(plano_subnacional)
					setFiles(plano_subnacional.arquivos)

					const dataGoals = metas_subnacionais.sort(function (a, b) {
						return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
					})

					setGoals(dataGoals)
					const dataStrategy = estrategias.sort(function (a, b) {
						return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
					})
					setStrategies(dataStrategy)
					const dataAcoes = acoes.sort(function (a, b) {
						return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
					})
					setActions(dataAcoes)
					setIsLoading(false)
				}
			}
		}
		fetchPlan()
	}, [])

	useEffect(() => {
		if (locationState) setShowToast(true)
	}, [])

	const closeToast = () => {
		setShowToast(false)
		window.history.replaceState({}, document.title)
	}

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto" />
			<FullPageLayout my={10}>
				<ProgressLine />

				<Stack mt={8} direction="row" align="center" justify="space-between">
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Plano de Educação
					</TextGradient>
				</Stack>

				{locationState && showToast && (
					<Center mt={8}>
						<StickyToast info={locationState} closeToast={closeToast} />
					</Center>
				)}

				{isLoading ? (
					<Center height="100vh">
						<CircularProgress isIndeterminate color="esmeralda.100" />
					</Center>
				) : (
					<>
						<Box
							bg="gray.200"
							borderRadius={8}
							py={8}
							px={4}
							alignContent="center"
							layerStyle="gradientBorderGrayBg"
							mt={8}
							mb={12}
						>
							<Text lineHeight="base" fontSize="2xl" fontWeight="bold">
								Município de Cidade
							</Text>
							<Text lineHeight="base" fontSize="lg" py={2}>
								O Município de Cidade possui Plano de Educação vigente. A Lei que aprova o Plano
								Municipal de Educação (PME) é a de número {plan?.numero_da_lei}, com data de início
								da vigência em {plan?.dt_inicio_vigencia} e data para o fim da vigência em{' '}
								{plan?.dt_fim_vigencia}.
							</Text>
							<Text lineHeight="base" fontSize="lg" py={2}>
								O Município tem {plan?.qtd_metas} metas e {plan?.qtd_estrategias} estratégias no seu
								PME. O período prevista para avaliação do Plano é Anual. A última avaliação
								aconteceu em {plan?.periodo_avaliacao_previsto}.
							</Text>
							<Text lineHeight="base" fontSize="lg" py={2}>
								O Ato legal que institui o responsável pela avaliação é um(a)
								{plan?.descricao}, com o número {plan?.numero} de {plan?.data} que institui o(a){' '}
								{plan?.responsavel} como instituição executora dessa avaliação.
							</Text>
							<Text lineHeight="base" fontSize="lg" py={2} mb={12}>
								Na lista a seguir estão as Lei sancionada pelo Prefeito, decretos e emendas que
								tornam atuais os dados do PME deste município.
							</Text>

							<FilesTable />
						</Box>

						<Stack direction="row" spacing={7} justify="flex-end" align="center">
							{!isArticulator && (
								<Button
									fontSize={18}
									fontWeight="medium"
									aria-label="Voltar para Articuladores"
									variant="cancel"
									borderRadius={5}
									onClick={() => navigate('/articulators')}
								>
									Voltar para Articuladores
								</Button>
							)}
							<Button
								fontSize={18}
								fontWeight="medium"
								aria-label="Editar Metas Cadastradas"
								variant="defaultHover"
								borderRadius={5}
								onClick={() => navigate('/metas-estrategias-acoes')}
							>
								Editar Metas Cadastradas
							</Button>
							<Button
								fontSize={18}
								fontWeight="medium"
								aria-label="Editar Informações"
								variant="defaultHover"
								borderRadius={5}
								disabled={!plan}
								onClick={() => navigate('edit')}
							>
								Editar Informações
							</Button>
						</Stack>
					</>
				)}
			</FullPageLayout>
		</>
	)
}
