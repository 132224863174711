import fetch from 'config/api'

export const createStrategy = async (data) => {
	return fetch({
		url: '/estrategia/',
		method: 'POST',
		data,
	})
		.then((res) => res.data)
		.catch((err) => err.response)
}
