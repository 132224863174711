import { createContext, useState, useContext } from 'react'

import { defaultTheme } from './default-theme'
import { highContrastTheme } from './high-contrast-theme'

export const ThemeContext = createContext()

export const useTheme = () => {
	return useContext(ThemeContext)
}

export const ThemeProvider = ({ children }) => {
	const [currentTheme, setCurrentTheme] = useState(defaultTheme)

	const isHighContrast = currentTheme === highContrastTheme

	const resetTheme = () => {
		setCurrentTheme(defaultTheme)
	}

	const value = {
		theme: currentTheme,
		setTheme: setCurrentTheme,
		resetTheme,
		isHighContrast,
	}

	return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
