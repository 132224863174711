export const createStrategiesSlice = (set) => ({
	strategies: [],
	selectedStrategies: [],
	setStrategies: (data) => set({ strategies: data }),
	addStrategy: (strategy) => set((state) => ({ strategies: [...state.strategies, ...strategy] })),
	addSelectedStrategy: (data) =>
		set((state) => ({
			selectedStrategies: [...state.selectedStrategies, data],
		})),
	removeSelectedStrategy: (strategy) =>
		set((state) => ({
			selectedStrategies: state.selectedStrategies.filter((element) => element !== strategy),
		})),
	resetSelectedStrategies: () => set({ selectedStrategies: [] }),
})
