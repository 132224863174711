import { Navigate } from 'react-router-dom'

import { userStore } from 'store/user'

export const PlanPipeline = () => {
	const user = userStore((state) => state.user)
	const isArticulator = user.pflcod === '0002'

	if (isArticulator) {
		return <Navigate to="/plano-de-educacao" />
	}

	return <Navigate to="/articulators" />
}
