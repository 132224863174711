import { FaCheckCircle } from 'react-icons/fa'
import { IoCloseOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import { Text, Flex, Icon, useBreakpointValue, IconButton } from '@chakra-ui/react'

export const StickyToast = ({ info, closeToast }) => {
	const navigate = useNavigate()

	const mobileScreen = useBreakpointValue({ base: true, md: false })

	const { message, status } = info

	return (
		<Flex
			borderRadius="xl"
			bg={status === 'success' ? 'esmeralda.100' : 'red'}
			color="white"
			w="max-content"
			alignItems="center"
			gridGap={4}
			py={2}
			px={4}
		>
			<Icon as={FaCheckCircle} boxSize={5} />
			<Flex gridGap={1}>
				<Text w="full" maxW="max-content" fontSize="md">
					{message}
				</Text>
				{info?.link?.show && (
					<>
						<Text w="full" maxW="max-content" fontSize="md">
							{info?.link?.action}
						</Text>
						<Text
							as="span"
							_hover={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() => navigate(info?.link.to)}
						>
							{!mobileScreen ? (
								<Text fontSize="md" fontWeight="bold">
									{info?.link?.destiny}
								</Text>
							) : (
								<Text
									fontSize="xs"
									fontWeight="bold"
									style={{
										whiteSpace: 'normal',
										wordWrap: 'break-word',
									}}
								>
									{info?.link?.destiny}
								</Text>
							)}
						</Text>
					</>
				)}
			</Flex>
			<IconButton
				px={0}
				variant="ghost"
				_hover={{ bg: 'none' }}
				icon={<Icon as={IoCloseOutline} boxSize={5} fontWeight="bold" />}
				onClick={closeToast}
			/>
		</Flex>
	)
}
