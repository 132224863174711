import { useNavigate } from 'react-router-dom'

import { Box, Flex, Text, Button } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { List } from './list'

export const Goals = () => {
	const navigate = useNavigate()
	const goals = useBoundStore((state) => state.goals)

	return (
		<Box p={2}>
			<Flex justify="space-between">
				<Text fontSize={30} fontWeight="bold">
					Metas Cadastradas
				</Text>
				<Flex gap={2}>
					<Button
						fontSize={18}
						fontWeight="medium"
						aria-label="Adicionar uma metas"
						variant="defaultHover"
						borderRadius={5}
						onClick={() => navigate('./cadastrar-meta')}
					>
						+ Adicionar uma meta
					</Button>
					<Button
						fontSize={18}
						fontWeight="medium"
						aria-label="Adicionar uma estratégia"
						variant="defaultHover"
						borderRadius={5}
						isDisabled={!goals.length}
						onClick={() => navigate('./cadastrar-estrategia')}
					>
						+ Adicionar uma estratégia
					</Button>
				</Flex>
			</Flex>
			<List />
		</Box>
	)
}
