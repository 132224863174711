import { number, object, string } from 'yup'

export const GoalsAndIndicatorsValidation = () =>
	object({
		indicatorDescription: string().required('Campo obrigatório'),
		foreseenGoalYear: number().required('Campo obrigatório'),
		foreseenGoalIndice: number().required('Campo obrigatório'),
		implementedGoalYear: number().required('Campo obrigatório'),
		implementedGoalIndice: number().required('Campo obrigatório'),
	})
