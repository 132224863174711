import { Stack, Text } from '@chakra-ui/react'

export const Field = ({ label, textAsLabel = false, children, ...stackProps }) => {
	const FieldLabel = () => (
		<Text fontSize="xl" fontWeight="bold">
			{label}
		</Text>
	)

	return (
		<>
			{textAsLabel && <FieldLabel />}
			<Stack direction={['column', 'row']} align="baseline" fontSize="xl" {...stackProps}>
				{!textAsLabel && <FieldLabel />}
				{children}
			</Stack>
		</>
	)
}
