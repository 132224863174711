import { Badge } from '@chakra-ui/react'

export const StatusBadge = ({ value }) => {
	if (value === 'A' || value === true) {
		return (
			<Badge variant="solid" colorScheme="green">
				ATIVO
			</Badge>
		)
	}

	return (
		<Badge variant="solid" colorScheme="gray">
			INATIVO
		</Badge>
	)
}
