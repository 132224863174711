const { TableContainer, Table, Thead, Tr, Th, Tbody, Td } = require('@chakra-ui/react')

const DataTable = () => {
	return (
		<TableContainer mt={8} mb={8} bg="#FFFFFF01" borderColor="#E2E8F0">
			<Table variant="simple" borderColor="#E2E8F0">
				<Thead>
					<Tr>
						<Th bg="#F7FAFC" color="#035677" fontWeight="bold">
							META 1
						</Th>
						<Th isNumeric bg="#F7FAFC" color="#035677" fontWeight="bold">
							ANO
						</Th>
						<Th bg="#F7FAFC" color="#035677" fontWeight="bold">
							CÓDIGO DO ESTADO
						</Th>
						<Th bg="#F7FAFC" color="#035677" fontWeight="bold">
							CÓDIGO DO MUNICÍPIO
						</Th>
						<Th bg="#F7FAFC" color="#035677" fontWeight="bold">
							GRAU ACADÊMICO
						</Th>
						<Th bg="#F7FAFC" color="#035677" fontWeight="bold">
							INDICADOR
						</Th>
						<Th bg="#F7FAFC" color="#035677" fontWeight="bold">
							QUANTIDADE DE TITULAÇÃO
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Tr>
						<Td>1</Td>
						<Td isNumeric>2011</Td>
						<Td>27</Td>
						<Td>2799</Td>
						<Td>2</Td>
						<Td>1B</Td>
						<Td>0</Td>
					</Tr>
					<Tr>
						<Td>2</Td>
						<Td isNumeric>2011</Td>
						<Td>27</Td>
						<Td>2799</Td>
						<Td>2</Td>
						<Td>1B</Td>
						<Td>0</Td>
					</Tr>
					<Tr>
						<Td>3</Td>
						<Td isNumeric>2011</Td>
						<Td>27</Td>
						<Td>2799</Td>
						<Td>2</Td>
						<Td>1B</Td>
						<Td>0</Td>
					</Tr>
				</Tbody>
			</Table>
		</TableContainer>
	)
}
export default DataTable
