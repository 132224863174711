import { Stack, Text, Flex } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { InputField, SelectCheckboxField, TextareaField } from 'modules/common/components'

export const Form = () => {
	const strategies = useBoundStore((state) => state.strategies)
	const selectedStrategies = useBoundStore((state) => state.selectedStrategies)

	const options = strategies.map((strategy) => ({
		label: `Estratégia ${strategy.numero}`,
		value: strategy.id,
	}))

	const defaultValue = selectedStrategies.map((element) =>
		options.find((option) => option.value.toString() === element),
	)

	return (
		<Stack>
			<Text fontSize="xl" fontWeight="bold">
				Adicionar Ação
			</Text>

			<Flex
				direction={{ base: 'column', lg: 'row' }}
				align={{ base: 'unset', lg: 'center' }}
				gap={{ base: 0, lg: 8 }}
			>
				<Text minW="fit-content" fontWeight="semibold">
					Estratégia(s) associada(s)*
				</Text>
				<SelectCheckboxField
					fieldName="strategies"
					placeholder="Selecione uma estratégia"
					options={options}
					defaultValue={defaultValue}
					width="full"
				/>
			</Flex>
			<InputField
				fieldName="actionNumber"
				label="Número da ação*"
				placeholder="Ex: 01.2.3"
				w="fit-content"
				display="flex"
				alignItems="center"
				gap={{ base: 0, lg: '80px' }}
			/>
			<TextareaField
				label="Descrição da ação*"
				fieldName="actionDescription"
				placeholder="Exemplo: Realizar, periodicamente, em regime de colaboração, levantamento da demanda por creche para a população de até 3 (três) anos, como forma de planejar a oferta e verificar o atendimento da demanda manifesta;"
				backgroundColor="white"
				w="80%"
				marginLeft={16}
			/>
		</Stack>
	)
}
