import { object, string } from 'yup'

export const StrategyValidation = () =>
	object({
		meta_subnacional: string().required('Campo obrigatório'),
		descricao: string().required('Campo obrigatório'),
		numero: string()
			.required('Campo obrigatório')
			.matches(/^[0-9]+$/, 'Preencha apenas com números'),
	})
