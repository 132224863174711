import fetch from 'config/api'

export const getArticulators = async () => {
	return fetch({
		url: '/perfil/',
		method: 'GET',
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('error', err))
}
