import { useNavigate } from 'react-router-dom'

import { Box, Flex, Text, Button } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { List } from './list'

export const Actions = () => {
	const navigate = useNavigate()
	const strategies = useBoundStore((state) => state.strategies)

	return (
		<Box p={2}>
			<Flex justify="space-between">
				<Text fontSize={30} fontWeight="bold">
					Ações Cadastradas
				</Text>
				<Button
					fontSize={18}
					fontWeight="medium"
					aria-label="Adicionar uma metas"
					variant="defaultHover"
					borderRadius={5}
					disabled={!strategies.length}
					onClick={() => navigate('./cadastrar-acao')}
				>
					+ Adicionar uma ação
				</Button>
			</Flex>
			<List />
		</Box>
	)
}
