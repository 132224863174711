import { useFormContext, useController } from 'react-hook-form'

import { Box, Input, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'

export const InputField = ({
	label,
	fieldName,
	inputType,
	placeholder,
	inputProps,
	labelProps,
	...wrapperProps
}) => {
	const { register } = useFormContext()

	const {
		fieldState: { error },
	} = useController({
		name: fieldName,
	})

	return (
		<FormControl
			w="full"
			{...wrapperProps}
			alignItems={error ? 'baseline' : wrapperProps?.alignItems}
			isInvalid={!!error}
		>
			{label && (
				<FormLabel minW="fit-content" htmlFor={fieldName} {...labelProps}>
					{label}
				</FormLabel>
			)}
			<Box w="inherit">
				<Input
					backgroundColor="white"
					placeholder={placeholder}
					type={inputType || 'text'}
					_placeholder={{
						fontSize: 'md',
						color: 'gray.300',
					}}
					{...inputProps}
					{...register(fieldName)}
				/>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</Box>
		</FormControl>
	)
}
