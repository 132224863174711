import fetch from 'config/api'

export const deleteStrategy = async (strategyId) => {
	return fetch({
		url: `/estrategia/${strategyId}/`,
		method: 'DELETE',
	})
		.then((res) => res.data)
		.catch((err) => console.error('error', err))
}
