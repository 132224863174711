import { uploadFile } from 'modules/education-plan/usecases'

export const uploadPlanFiles = (files) => {
	return Promise.all(
		files.map(async (file) => {
			const { id } = await uploadFile(file.name, file)
			if (id) {
				return id
			}
		}),
	)
}
