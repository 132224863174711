export const Button = {
	baseStyle: {
		fontWeight: 'normal',
		fontsize: 'sm',
		padding: 0,
	},
	variants: {
		default: {
			padding: 4,
			border: '1px solid',
			borderRadius: '3xl',
		},
		contrast: {
			bg: 'white',
			color: 'black',
		},
		nav: {
			px: 4,
			py: 2,
			color: 'white',
			_hover: {
				bgColor: '#3f9e6e',
			},
		},
		home: {
			bgColor: 'white',
			_hover: {
				bgColor: '#3f9e6e',
			},
		},
		gradient: {
			border: '3px solid',
			borderColor: '#F4F4F4',
			borderRadius: '3xl',
			background: 'none',
		},
	},
}
