import { useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react'

import { UploadZone } from '..'

import { useBoundStore } from 'store'

import { formatFileData } from 'modules/education-plan/helpers'

export const FileField = ({ fieldName, btnLabel, ...boxProps }) => {
	const { trigger } = useFormContext()
	const setFiles = useBoundStore((state) => state.setFiles)

	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController({
		name: fieldName,
	})

	const handleFiles = (files) => {
		const formatedFiles = files.map((file) => {
			const { name, size } = file
			return formatFileData({ name, size })
		})

		setFiles(formatedFiles)
	}

	const onDrop = useCallback(
		(acceptedFiles) => {
			onChange(acceptedFiles)
			handleFiles(acceptedFiles)
		},
		[onChange, setFiles],
	)

	const onCancel = async () => {
		!value && (await trigger(fieldName, { shouldFocus: true }))
	}

	return (
		<Box {...boxProps}>
			<FormControl isInvalid={!!error}>
				<UploadZone
					onDrop={onDrop}
					onCancel={onCancel}
					btnLabel={btnLabel}
					w="fit-content"
					variant="defaultHover"
				/>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
		</Box>
	)
}
