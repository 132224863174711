import fetch from 'config/api'

export const getGoalsYear = async (meta, start_year, end_year) => {
	return fetch({
		url: `dados_portal/${meta}/${start_year}/${end_year}/`,
		method: 'GET',
	})
		.then((res) => {
			// const { results } = res.data
			// if (results) {
			// 	return results.map((goal) => ({
			// 		checkbox: goal.id,
			// 		...goal,
			// 	}))
			// }
			return res.data
		})
		.catch((err) => console.error('error', err))
}
