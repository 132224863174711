import fetch from 'config/api'

export const createUser = async (data) => {
	return fetch({
		url: '/usuario/create/',
		method: 'POST',
		data,
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => err.response)
}
