import { Stack } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { InputField, SelectField, SelectCheckboxField } from 'modules/common/components'

import { legalAccountableOptions, legalActDescriptionOptions } from '../helpers'
import { Field } from './field'

export const ResponsibleField = () => {
	const plan = useBoundStore((state) => state.plan)

	const defaultValue = plan?.responsavel.map((element) =>
		legalAccountableOptions.find((option) => option.value === element),
	)

	return (
		<>
			<Field label="Ato legal que institui o responsável pela avaliação" textAsLabel>
				<SelectCheckboxField
					options={legalAccountableOptions}
					defaultValue={defaultValue}
					fieldName="legalActResponsible"
					placeholder="Selecione os responsáveis pela avaliação do PME"
				/>
			</Field>

			<Stack direction={['column', 'row']}>
				<SelectField
					label="Descrição *"
					fieldName="legalActDescription"
					placeholder="Selecione um ato normativo"
					options={legalActDescriptionOptions}
					w="full"
				/>

				<InputField label="Número*" fieldName="legalActNumber" placeholder="Ex: 1.200" />

				<InputField
					label="Data *"
					fieldName="legalActDate"
					inputType="date"
					placeholder="Ex: 02/07/2015"
				/>
			</Stack>
		</>
	)
}
