import { useRef } from 'react'

import { Stack, Link } from '@chakra-ui/react'

import {
	SectionsMenu,
	AboutPNE,
	AboutConectaPNE,
	HowConectaPneWorks,
	StepByStep,
	PneGoals,
} from './components'

const LandingPage = () => {
	const aboutPneRef = useRef(null)
	const aboutConectaPneRef = useRef(null)
	const howItWorksRef = useRef(null)
	const stepsRef = useRef(null)
	const pneGoalsRef = useRef(null)

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto" />
			<Stack>
				<SectionsMenu
					aboutPneRef={aboutPneRef}
					aboutConectaPneRef={aboutConectaPneRef}
					howItWorksRef={howItWorksRef}
					pneGoalsRef={pneGoalsRef}
				/>
				<div name="maincontent" id="maincontent">
					<AboutPNE ref={aboutPneRef} />
				</div>

				<PneGoals ref={pneGoalsRef} />
				<AboutConectaPNE ref={aboutConectaPneRef} />
				<HowConectaPneWorks ref={howItWorksRef} stepsRef={stepsRef} />
				<StepByStep ref={stepsRef} />
			</Stack>
		</>
	)
}

export default LandingPage
