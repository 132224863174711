const objetivos = [
	{
		title: '1',
		text: 'Apoiar o monitoramento e avaliação dos planos de educação pelas comissões responsáveis, dando suporte à tomada de decisão.',
	},
	{
		title: '2',
		text: 'Prover acesso público aos dados educacionais relacionados ao PNE, para toda a sociedade brasileira.',
	},
]
export default objetivos
