import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Link, Stack, Button, Text } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'

import { FullPageLayout, TextGradient } from 'modules/common/components'

import { createUser, updateUser } from '../usecases'
import { ProfileValidation } from '../validators'
import { Form } from './form'

export const ProfileForm = () => {
	const navigate = useNavigate()
	const validation = ProfileValidation()

	const [foundUser, setFoundUser] = useState({})

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
	})

	const { isSubmitSuccessful } = methods.formState

	const cpfValidation = (response) => {
		if (!response.status) return

		const { status, data } = response

		if (status !== 201) {
			const [errorMessage] = data.cpf
			methods.setError('cpf', { type: 'string', message: errorMessage })
		}
	}

	const onSubmit = async (values) => {
		const { cpf, nome, celular, email, perfil_do_usuario, active } = values

		const payload = {
			cpf,
			nome,
			celular,
			email,
			perfil_do_usuario: parseInt(perfil_do_usuario),
			active: active === 'true',
		}

		try {
			if (foundUser.cpf) {
				const res = await updateUser(payload, cpf)
				cpf !== parseInt(foundUser.cpf) && cpfValidation(res)
			} else {
				const res = await createUser(payload)
				cpfValidation(res)
			}
		} catch (err) {
			console.error('GOT ERROR', err)
		}
	}

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto" />
			<FullPageLayout>
				<Stack mt={8} gap={4}>
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Gestão de Perfis
					</TextGradient>

					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Stack border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={6}>
								<Form setFoundUser={setFoundUser} />

								<Text pt={4} fontSize="xl">
									Observação: Os campos com * são de preenchimento obrigatório.
								</Text>
							</Stack>

							<Stack direction="row" justify="end" mt={7} mb={16}>
								<Button
									variant="cancel"
									fontSize="lg"
									fontWeight="semibold"
									onClick={() => navigate('./..')}
								>
									Voltar
								</Button>
								<Button
									type="submit"
									variant="defaultHover"
									fontSize="lg"
									fontWeight="semibold"
									isDisabled={isSubmitSuccessful}
								>
									Salvar
								</Button>
							</Stack>
						</form>
					</FormProvider>
				</Stack>
			</FullPageLayout>
		</>
	)
}
