import fetch from 'config/api'

export const getFile = async (fileId) => {
	return fetch({
		url: `/arquivo/detalhes/${fileId}/`,
		method: 'GET',
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('error', err))
}
