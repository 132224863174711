import { Stack, Text } from '@chakra-ui/react'

import { TextareaField } from 'modules/common/components'

import { ForeseenField } from './foreseen'
import { SelectGraphicField } from './graphic-field'
import { ImplementedField } from './implemented'

const placeholder =
	'ex: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet sagittis elit. Nullam condimentum, enim quis hendrerit feugiat, quam erat consequat justo, vitae porta ipsum felis volutpat urna. Fusce suscipit eros lacus, id tristique nibh dictum sed. Aenean euismod libero'

export const Form = () => {
	return (
		<Stack spacing={16}>
			<TextareaField
				label="Descrição do indicador*"
				fieldName="indicatorDescription"
				placeholder={placeholder}
				backgroundColor="white"
				minHeight={36}
				padding={8}
				labelProps={{ fontSize: 'xl' }}
			/>

			<ForeseenField />

			<ImplementedField />

			<Text fontSize={'xl'} fontWeight={'bold'}>
				Equipes:
			</Text>

			<TextareaField
				label="Descreva a equipe de avaliação"
				fieldName="teamDescription"
				placeholder={placeholder}
				backgroundColor="white"
				minHeight={36}
				padding={8}
				labelProps={{ fontSize: 'xl' }}
			/>

			<SelectGraphicField />

			<Text fontSize={'lg'}>Observação: Os campos com * são de preenchimento obrigatório.</Text>
		</Stack>
	)
}
