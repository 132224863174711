import { useFormContext } from 'react-hook-form'

import { Stack, Text, Button } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { InputField, SelectField } from 'modules/common/components'

import { ForeseenGoalsTable } from './table'

export const ForeseenField = () => {
	const setForeseenGoals = useBoundStore((state) => state.setForeseenGoals)

	const { getValues } = useFormContext()
	const { foreseenGoalYear, foreseenGoalIndice } = getValues()

	const disableButton = !foreseenGoalYear || !foreseenGoalIndice

	const options = [
		{
			id: 1,
			title: '2024',
			value: 2024,
		},
	]

	const onClick = () => {
		const { foreseenGoalYear, foreseenGoalIndice } = getValues()

		if (foreseenGoalYear && foreseenGoalIndice) {
			const data = { foreseenGoalYear, foreseenGoalIndice }
			setForeseenGoals(data)
		}
	}

	return (
		<>
			<Stack spacing={4}>
				<Text fontSize={'xl'} fontWeight={'bold'}>
					Meta prevista:
				</Text>
				<Stack direction="row" justifyContent={'space-between'} spacing={10}>
					<SelectField
						label={'Ano *'}
						fieldName={'foreseenGoalYear'}
						placeholder={'Selecione um ano'}
						options={options}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}
						gap={'50%'}
						labelProps={{ fontSize: 'xl' }}
					/>
					<Button
						variant={'defaultHover'}
						width={'25%'}
						fontSize={'md'}
						fontWeight={'semibold'}
						onClick={onClick}
						disabled={disableButton}
					>
						+ Adicionar um ano
					</Button>
				</Stack>
				<InputField
					label={'Índice de atingimento previsto no plano*'}
					placeholder={'Exemplo: 23,5%'}
					fieldName={'foreseenGoalIndice'}
					display={'flex'}
					gap={20}
					labelProps={{ fontSize: 'xl ' }}
				/>
			</Stack>
			<ForeseenGoalsTable />
		</>
	)
}
