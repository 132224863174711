import { extendTheme } from '@chakra-ui/react'

import { StepsTheme as Steps } from 'chakra-ui-steps'

import { colors } from '../colors'
import { Button, Input, Text, Link, Checkbox, Stepper } from './components'

const fonts = {
	heading: 'Roboto, sans-serif',
	body: 'Roboto, sans-serif,',
}

const layerStyles = {
	gradientBorder: {
		border: '3px solid',
		borderColor: 'transparent',
		background: `linear-gradient(#F4F4F4, #F4F4F4) padding-box,
		linear-gradient(45deg, #3F9E6E, #000055) border-box`,
	},
	gradientBorderWhiteBg: {
		border: '3px solid',
		borderColor: 'transparent',
		background: `linear-gradient(#fff, #fff) padding-box,
		linear-gradient(45deg, #3F9E6E, #000055) border-box`,
	},
	gradientBorderGrayBg: {
		border: '2px solid',
		borderColor: 'transparent',
		background: `linear-gradient(#F5F5F5, #F5F5F5) padding-box,
		linear-gradient(45deg, #8CC5A8, #666699) border-box`,
	},
}

export const defaultTheme = extendTheme({
	colors,
	fonts,
	layerStyles,
	components: {
		Steps,
		Button,
		Input,
		Text,
		Link,
		Checkbox,
		Stepper,
	},
})
