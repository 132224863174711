export const createFileSlice = (set) => ({
	files: [],
	setFiles: (data) =>
		set((state) => ({
			files: [...state.files, ...data],
		})),
	removeFile: (file) =>
		set((state) => ({
			files: state.files.filter((element) => element !== file),
		})),
	resetFiles: () => set({ files: [] }),
})
