import env from '@beam-australia/react-env'

import axios from 'axios'

import { addAuthentication, validateTokens, refreshToken } from '../helpers'

const baseURL = env('API_URL')

const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
})

api.interceptors.request.use(async (request) => {
	const withoutAuth = !request.url?.includes('auth')
	const { isAccessValid, isRefreshValid, refresh_token } = validateTokens()

	if (!isAccessValid && isRefreshValid && refresh_token && withoutAuth) {
		await refreshToken(refresh_token, isRefreshValid)
	}

	return addAuthentication(request)
})

const fetch = (requestConfig) => api.request(requestConfig)

export default fetch
