/* eslint-disable indent */
export const createEvaluationFormSlice = (set) => ({
	formData: {},
	pdfUrl: '',
	evaluationGoals: [],
	foreseenGoals: [],
	implementedGoals: [],
	selectedGraphics: [],
	setFormData: (data) => set((state) => ({ formData: { ...state.formData, ...data } })),
	setPdfUrl: (data) => set({ pdfUrl: data }),
	setSelectedGraphics: (data) =>
		set((state) => ({ selectedGraphics: [...state.selectedGraphics, data] })),

	setForeseenGoals: (data) => set((state) => ({ foreseenGoals: [...state.foreseenGoals, data] })),
	setImplementedGoals: (data) =>
		set((state) => ({ implementedGoals: [...state.implementedGoals, data] })),

	setEvaluationGoals: (data) =>
		set((state) => ({
			evaluationGoals: state.evaluationGoals.map((item) => {
				if (item.year === data.year) {
					return { ...data, ...item }
				}
				return item
			}),
		})),
})
