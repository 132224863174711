import fetch from 'config/api'

export const createAction = async (data) => {
	return fetch({
		url: '/acao/',
		method: 'POST',
		data,
	})
		.then((res) => res.data)
		.catch((err) => err.response)
}
