import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Link, Stack, Text } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBoundStore } from 'store'

import { FullPageLayout, TextGradient, ProgressLine } from 'modules/common/components'

import { Form } from './form'
import { createStrategy } from './usecases'
import { StrategyValidation } from './validators'

export const StrategiesForm = () => {
	const validation = StrategyValidation()
	const navigate = useNavigate()

	const selectedGoal = useBoundStore((state) => state.selectedGoal)
	const setSelectedGoal = useBoundStore((state) => state.setSelectedGoal)

	const goals = useBoundStore((state) => state.goals)

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
		defaultValues: {
			meta_subnacional: selectedGoal || undefined,
		},
	})

	const { isSubmitSuccessful } = methods.formState

	const handleCancel = () => {
		setSelectedGoal(null)
		navigate('/metas-estrategias-acoes')
	}

	const handleResponse = (response) => {
		if (!response.status) {
			const { numero: associatedGoal } = goals?.find(
				(goal) => goal.id === response.meta_subnacional,
			)
			const state = {
				message: `Estratégia associada à Meta ${associatedGoal} adicionada ao Plano Municipal de Educação!`,
				status: 'success',
			}
			return state
		}

		return response
	}

	const onSubmit = async (values) => {
		const payload = {
			...values,
			meta_subnacional: parseInt(values?.meta_subnacional),
		}

		try {
			let state

			const response = await createStrategy(payload)
			if (response) {
				state = handleResponse(response)
				setSelectedGoal(null)
			}

			navigate('./..', { state })
		} catch (err) {
			console.error('GOT ERROR', err)
		}
	}

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto" />
			<FullPageLayout mt={10} mb={10}>
				<ProgressLine />

				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
					pt={12}
				>
					Metas / Estratégias / Ações
				</TextGradient>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box p={4} my={12} borderRadius="3xl" layerStyle="gradientBorderGrayBg">
							<Form />
						</Box>

						<Stack direction="row" justify="space-between">
							<Text fontSize={18}>
								Observação: Os campos com * são de preenchimento obrigatório
							</Text>
							<Stack direction="row" justify="flex-end" mt={10}>
								<Button variant="cancel" fontWeight="semibold" onClick={handleCancel}>
									Cancelar
								</Button>
								<Button
									type="submit"
									variant="defaultHover"
									fontWeight="semibold"
									disabled={isSubmitSuccessful}
								>
									Salvar
								</Button>
							</Stack>
						</Stack>
					</form>
				</FormProvider>
			</FullPageLayout>
		</>
	)
}

// eslint-disable-next-line no-lone-blocks
{
	/* <Stack spacing={7}>
								<Text fontSize={25} fontWeight="bold" mt={4}>
									Adicionar Estratégia
								</Text>
								<Box w="full">
									<Stack direction="row" mb={8}>
										<Text fontSize={20} mr={2}>
											Meta Associada*
										</Text>
										<Select
											placeholder={selectedGoal || 'Selecione uma meta'}
											w={200}
											defaultValue={selectedGoal | ''}
											bg="white"
										></Select>
									</Stack>
									<Stack direction="row" mt={8}>
										<Text fontSize={20} mr={2}>
											Número da Estratégia*
										</Text>
										<Input
											w="fit-content"
											bg="white"
											type="number"
											placeholder="Exemplo: 120"
											{...register('numero', { required: true })}
										/>
									</Stack>
								</Box>
							</Stack> */
}
