import fetch from 'config/api'

export const updatePlan = async (id, data) => {
	return fetch({
		url: `/plano_subnacional/${id}/`,
		method: 'PUT',
		data,
	})
		.then((res) => res.data)
		.catch((err) => err.response)
}
