import fetch from 'config/api'

export const createPlan = async (data) => {
	return fetch({
		url: '/plano_subnacional/',
		method: 'POST',
		data,
	})
		.then((res) => res.data)
		.catch((err) => err.response)
}
