import fetch from 'config/api'

export const generatePdf = async (data) => {
	return fetch({
		url: '/gerarPDF/',
		method: 'POST',
		data,
		responseType: 'blob',
	})
		.then((res) => {
			const blob = new Blob([res.data], { type: res.data.type })
			const url = window.URL.createObjectURL(blob)
			return url
		})
		.catch((err) => err.response)
}
