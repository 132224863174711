import React from 'react'
import { Outlet } from 'react-router-dom'

import { Govbar, Navbar, Footer } from '..'

export const OutletLayout = () => {
	return (
		<>
			<Govbar />
			<Navbar />
			<Outlet />
			<Footer />
		</>
	)
}
