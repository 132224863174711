import { useMemo } from 'react'

import { useBoundStore } from 'store'

import { TableWithPagination } from 'modules/common/components/CustomTable'

export const ForeseenGoalsTable = () => {
	const data = useBoundStore((state) => state.foreseenGoals)

	const columns = useMemo(
		() => [
			{
				Header: 'ANO',
				accessor: 'foreseenGoalYear',
			},
			{
				Header: 'ÍNDICE DE ATINGIMENTO PREVISTO NO PLANO',
				accessor: 'foreseenGoalIndice',
			},
			{
				Header: '',
				accessor: 'action',
			},
		],
		[],
	)

	if (!data.length) return

	return <TableWithPagination data={data} columns={columns} bg="white" />
}
