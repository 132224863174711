import { Navigate } from 'react-router-dom'

import { userStore } from 'store/user'

import { useHasSession } from 'modules/common/hooks'

export const RouteGuard = ({ children }) => {
	const isAuthenticated = useHasSession()
	const clearTokens = userStore((state) => state.clearTokens)

	if (isAuthenticated) {
		return <>{children}</>
	}

	clearTokens()
	return <Navigate to="/" />
}
