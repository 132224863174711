/* eslint-disable react/display-name */
import { memo } from 'react'
import { useFormContext, useController } from 'react-hook-form'

import { Box, Select, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'

export const SelectField = memo(
	({ label, fieldName, placeholder, options, labelProps, ...wrapperProps }) => {
		const { control } = useFormContext()
		const {
			field,
			fieldState: { error },
		} = useController({
			control,
			name: fieldName,
		})

		return (
			<FormControl
				w="full"
				{...wrapperProps}
				alignItems={error ? 'baseline' : wrapperProps?.alignItems}
				isInvalid={!!error}
			>
				<FormLabel minW="fit-content" htmlFor={fieldName} fontWeight="semibold" {...labelProps}>
					{label}
				</FormLabel>
				<Box w="inherit">
					<Select
						backgroundColor="white"
						fontSize="md"
						placeholder={placeholder}
						onChange={(e) => field.onChange(e)}
						value={field?.value || undefined}
					>
						{options.map(({ id, value, title }) => (
							<option key={id} value={value}>
								{title}
							</option>
						))}
					</Select>
					{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
				</Box>
			</FormControl>
		)
	},
)
