import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Heading, Text, Button } from '@chakra-ui/react'

import { userStore } from 'store/user'

import { FullPageLayout, TextGradient } from 'modules/common/components'

import { getTermo, updateTermo } from './usecases'

export const TermoAdesao = () => {
	const navigate = useNavigate()
	const setUser = userStore((state) => state.setUser)
	const user = userStore((state) => state.user)
	const { nome, cpf, pfl_pk, pfldsc, termo_aceito, ta_resposta_pk } = user

	const [term, setTerm] = useState(null)

	useEffect(() => {
		const loadTerm = async () => {
			const data = await getTermo(ta_resposta_pk)
			const acceptedDate = new Date(data.data_resposta).toLocaleString('pt-BR')
			setTerm({ ...data, data_resposta: acceptedDate })
		}
		if (ta_resposta_pk) loadTerm()
	}, [ta_resposta_pk])

	const handleAcceptTerm = async () => {
		const id = ta_resposta_pk

		const data = {
			termo: term.termo,
			resposta: true,
			perfil: pfl_pk,
			cpf,
		}

		const res = await updateTermo(id, data)
		if (res) {
			setUser({ ...user, termo_aceito: res.resposta })
			navigate('/home', { replace: true })
		}
	}

	return (
		<FullPageLayout>
			<Stack py={16} spacing={6}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
				>
					Termo de adesão
				</TextGradient>
				<Box
					bg="gray.180"
					px={6}
					py={8}
					border="1px"
					borderRadius="3xl"
					layerStyle="gradientBorder"
				>
					<Heading as="h4" size="md" pb={6}>
						Termo de adesão à Plataforma Conecta PNE
					</Heading>
					<Text pb={6}>
						{nome}, portador(a) do CPF nº {cpf}, {pfldsc} de [ESTADO/MUNICÍPIO], resolve firmar o
						presente Termo de Adesão à Plataforma Conecta PNE, consideradas as condições abaixo:
					</Text>
					<Text>CLÁUSULA PRIMEIRA - DO OBJETO</Text>
					<Text>
						O presente Termo tem por objeto a adesão do ente federativo à Plataforma Conecta PNE,
						instituída pelo Ministério da Educação (MEC), nos termos da Portaria MEC n. [XX], de
						[dia] de [mês] de [ano].
					</Text>
					<Text>
						CLÁUSULA SEGUNDA - DAS RESPONSABILIDADES DA SECRETARIA DE EDUCAÇÃO BÁSICA DO MINISTÉRIO
						DA EDUCAÇÃO
					</Text>
					<Text>
						I - instituir e disponibilizar a Plataforma Conecta PNE às Secretarias de Educação dos
						Estados, dos Municípios, e do Distrito Federal;
					</Text>
					<Text>
						II - promover as condições necessárias para o perfeito funcionamento da Plataforma
						Conecta PNE, garantindo sua estrutura física, técnica e de suporte;
					</Text>
					<Text>
						III - promover a formação, priorizando a utilização de metodologias e ferramentas de
						educação a distância para auxiliar o trabalho dos coordenadores Conecta PNE e
						articuladores da Plataforma Conecta PNE;
					</Text>
					<Text>IV - revisar e elaborar materiais para formação;</Text>
					<Text>V - manter canal de comunicação permanente com os Coordenadores Conecta PNE;</Text>
					<Text>VI - disponibilizar atendimento especializado.</Text>
					<Text>CLÁUSULA TERCEIRA - DAS RESPONSABILIDADES DO ENTE FEDERADO</Text>
					<Text>
						I - aderir às ações de monitoramento e avaliação dos Planos de Educação dos Estados, dos
						Municípios e do Distrito Federal pela Plataforma Conecta PNE;
					</Text>
					<Text>
						II - designar o articulador local, responsável pelo preenchimento da Plataforma Conecta
						PNE;
					</Text>
					<Text>
						III - promover as condições necessárias para o eficiente preenchimento da Plataforma
						+PNE, incluindo o acesso a dispositivo com conexão à internet;
					</Text>
					<Text>
						IV - fomentar e garantir a participação do articulador local nas atividades de formação,
						custeando o deslocamento, a hospedagem e a alimentação, sempre que necessário;
					</Text>
					<Text>
						V - assegurar a imediata substituição do articulador local que sofra qualquer
						impedimento na execução de suas atividades;
					</Text>
					<Text>
						VI - acompanhar o planejamento e a execução das ações de monitoramento e avaliação dos
						Planos de Educação dos Estados, dos Municípios e do Distrito Federal;
					</Text>
					<Text>
						VII - responsabilizar-se pela veracidade das informações inseridas na Plataforma +PNE.
					</Text>
					<Text>CLÁUSULA QUARTA - DAS DISPOSIÇÕES FINAIS</Text>
					<Text>
						I - O Ministério da Educação (MEC) poderá utilizar os dados da Plataforma +PNE para
						subsidiar o planejamento das políticas educacionais, observando-se a Lei nº 13.709, de
						14 de agosto de 2018, que dispõe sobre a Lei Geral de Proteção de Dados Pessoais (LGPD).
					</Text>
				</Box>
				{termo_aceito ? (
					<Box fontWeight="medium">
						<Text>Termo aceito em: {term?.data_resposta}</Text>
						<Text>{nome}</Text>
					</Box>
				) : (
					<Button
						variant="nav"
						w="fit-content"
						bg="esmeralda.100"
						alignSelf="end"
						onClick={handleAcceptTerm}
					>
						Aceitar termo de adesão
					</Button>
				)}
			</Stack>
		</FullPageLayout>
	)
}
