import { useFormContext, useController } from 'react-hook-form'
import Select, { components } from 'react-select'

import { FormControl, FormErrorMessage, Checkbox } from '@chakra-ui/react'

export const SelectCheckboxField = ({
	options,
	defaultValue,
	fieldName,
	placeholder,
	...boxProps
}) => {
	const { control } = useFormContext()
	const {
		field,
		fieldState: { error },
	} = useController({
		control,
		name: fieldName,
	})

	const Option = (props) => {
		return (
			<components.Option {...props}>
				<Checkbox isChecked={props.isSelected}>{props.label}</Checkbox>
			</components.Option>
		)
	}

	return (
		<FormControl isInvalid={!!error} {...boxProps}>
			<Select
				isMulti
				allowSelectAll={true}
				options={options}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				components={{ Option }}
				defaultValue={defaultValue || null}
				onChange={(data) => field.onChange(data)}
				placeholder={placeholder}
				theme={(theme) => ({
					...theme,
					borderRadius: '8px',
					colors: {
						...theme.colors,
						primary: '#3F9E6E',
					},
				})}
				styles={{
					control: (baseStyles) => ({
						...baseStyles,
						background: 'white',
						fontSize: '16px',
						borderColor: error ? 'red' : 'primary',
					}),
					option: (baseStyles) => ({
						...baseStyles,
						borderRadius: '8px',
						':hover': {
							backgroundColor: '#8CC5A8',
						},
					}),
					multiValue: (baseStyles) => ({
						...baseStyles,
						backgroundColor: '#3F9E6E',
						color: 'white',
						borderRadius: '8px',
						fontSize: '16px',
						fontWeight: '400',
					}),
					multiValueLabel: (baseStyles) => ({
						...baseStyles,
						color: 'white',
					}),
				}}
			/>
			{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
		</FormControl>
	)
}
