export const Stepper = {
	baseStyle: {
		indicator: {
			bgColor: 'white',
			borderColor: '#3F9E6E',

			'&[data-status=complete]': {
				bgColor: 'white',
				borderColor: '#3F9E6E',
				borderWidth: '2px',
			},

			'&[data-status=active]': {
				bgColor: '#3F9E6E',
				borderColor: '#3F9E6E',
				borderWidth: '2px',
			},

			'&[data-status=incomplete]': {
				bgColor: 'white',
				borderColor: '#3F9E6E',
				borderWidth: '2px',
			},
		},
	},
}
