import { useEffect } from 'react'
import { useFormContext, useController } from 'react-hook-form'

import { Box, Input, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'

import { getUserByCpf } from '../usecases'

export const CpfField = ({ setFoundUser }) => {
	const { register, setValue, reset } = useFormContext()

	const {
		field,
		fieldState: { error },
	} = useController({
		name: 'cpf',
	})

	const updateFormValues = (data) => {
		const { nome, celular, email, perfil_do_usuario, active } = data
		!!nome && setValue('nome', nome, { shouldValidate: true })
		!!celular && setValue('celular', celular, { shouldValidate: true })
		!!email && setValue('email', email, { shouldValidate: true })
		!!perfil_do_usuario &&
			setValue('perfil_do_usuario', perfil_do_usuario.id, { shouldValidate: true })
		setValue('active', active, { shouldValidate: true })
	}

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const data = await getUserByCpf(field.value)
				if (data) {
					updateFormValues(data)
					setFoundUser(data)
				} else {
					setFoundUser({})
					reset({
						nome: '',
						celular: '',
						email: '',
						perfil_do_usuario: '',
						active: '',
					})
				}
			} catch (err) {
				console.error(err)
			}
		}
		field.value?.length === 11 && fetchUser()
	}, [field.value])

	return (
		<FormControl
			w="full"
			display={{ base: 'block', lg: 'flex' }}
			alignItems={error ? 'baseline' : 'center'}
			gap={{ base: 0, lg: 16 }}
			isInvalid={!!error}
		>
			<FormLabel minW="fit-content" htmlFor="cpf" fontSize="2xl" fontWeight="bold">
				CPF *
			</FormLabel>
			<Box w="inherit">
				<Input
					backgroundColor="white"
					placeholder="000.000.000-00"
					maxLength={11}
					_placeholder={{
						fontSize: 'md',
						color: 'gray.300',
					}}
					{...register('cpf')}
				/>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</Box>
		</FormControl>
	)
}
