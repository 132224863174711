import { useFormContext, useController } from 'react-hook-form'

import {
	Box,
	Stack,
	Text,
	CheckboxGroup,
	Checkbox,
	FormControl,
	FormErrorMessage,
} from '@chakra-ui/react'

export const CheckboxField = ({ groupName, options, ...boxProps }) => {
	const { control } = useFormContext()
	const {
		field,
		fieldState: { error },
	} = useController({
		control,
		name: groupName,
	})

	return (
		<Box w="full" {...boxProps}>
			<FormControl isInvalid={!!error}>
				<CheckboxGroup onChange={(value) => field.onChange(value)}>
					<Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 'none', md: 4 }}>
						<Box>
							{options.slice(0, 10).map((option) => (
								<Checkbox key={option.id} value={option.id.toString()} w="full">
									<Text fontSize="lg" fontWeight="medium" color="gray.900">
										{option.numero} - {option.titulo}
									</Text>
								</Checkbox>
							))}
						</Box>
						<Box>
							{options.slice(10).map((option) => (
								<Checkbox key={option.id} value={option.id.toString()} w="full" colorScheme="green">
									<Text fontSize="lg" fontWeight="medium" color="gray.900">
										{option.numero} - {option.titulo}
									</Text>
								</Checkbox>
							))}
						</Box>
					</Stack>
				</CheckboxGroup>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
		</Box>
	)
}
