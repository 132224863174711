import { Navigate, Outlet } from 'react-router-dom'

import { userStore } from 'store/user'

export const RequirePermission = ({ allowedRoles }) => {
	const user = userStore((state) => state.user)

	return allowedRoles.includes(user?.perfil_do_usuario?.nome?.toUpperCase()) ? (
		<Outlet />
	) : (
		<Navigate to="/" replace />
	)
}
