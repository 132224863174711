import fetch from 'config/api'

export const deleteGoal = async (id) => {
	return fetch({
		url: `/meta_subnacional/${id}/`,
		method: 'DELETE',
	})
		.then((res) => res.data)
		.catch((err) => console.error('error', err))
}
