import { useMemo } from 'react'

import { useBoundStore } from 'store'

import { TableWithPagination } from 'modules/common/components/CustomTable'

export const ImplementedGoalsTable = () => {
	const foreseen = useBoundStore((state) => state.foreseenGoals)
	const implemented = useBoundStore((state) => state.implementedGoals)

	const foreseenLookup = foreseen.reduce((acc, { foreseenGoalYear, foreseenGoalIndice }) => {
		acc[foreseenGoalYear] = foreseenGoalIndice
		return acc
	}, {})

	const data = implemented.map(({ implementedGoalYear, implementedGoalIndice }) => {
		return {
			implementedGoalYear,
			implementedGoalIndice,
			foreseenGoalIndice: foreseenLookup[implementedGoalYear],
		}
	})

	const columns = useMemo(
		() => [
			{
				Header: 'ANO',
				accessor: 'implementedGoalYear',
			},
			{
				Header: 'ÍNDICE DE ATINGIMENTO PREVISTO NO PLANO',
				accessor: 'foreseenGoalIndice',
			},
			{
				Header: 'ÍNDICE DE ATINGIMENTO EXECUTADO NO PLANO',
				accessor: 'implementedGoalIndice',
			},
			{
				Header: '',
				accessor: 'action',
			},
		],
		[],
	)

	if (!data.length) return

	return <TableWithPagination data={data} columns={columns} bg="white" />
}
