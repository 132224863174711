import { decode, isValid } from 'config/helpers/tokens-validation'
import { userStore } from 'store/user'

export const useHasSession = () => {
	const accessToken = userStore((state) => state.accessToken)
	const refreshToken = userStore((state) => state.refreshToken)

	if (accessToken && refreshToken) {
		const decodedAccess = decode(accessToken)
		const decodedRefresh = decode(refreshToken)

		const validAccess = isValid(decodedAccess.exp)
		const validRefresh = isValid(decodedRefresh.exp)

		return validAccess || validRefresh
	}

	return false
}
