export const Checkbox = {
	baseStyle: {
		control: {
			bg: 'white',
			_checked: {
				bg: '#3F9E6E',
				border: 'none',
			},
		},
	},
}
