import fetch from 'config/api'

export const getPlanMea = async (planId) => {
	return fetch({
		url: `/plano_subnacional/MEA/${planId}/`,
		method: 'GET',
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('error', err))
}
