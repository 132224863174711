import { Link } from 'react-router-dom'

import { Button, Stack } from '@chakra-ui/react'

import { ResponsiveLogo, FullPageLayout } from '..'

import { useHasSession } from 'modules/common/hooks'

import { UserActionsButton } from '../UserActionsButton'

export const Navbar = () => {
	const NavButtons = () => {
		const isAuthenticated = useHasSession()

		if (!isAuthenticated) {
			return (
				<Stack direction={{ base: 'column', md: 'row' }} align="center">
					<Link to="/dash">
						<Button variant="default">Apoio ao monitoramento</Button>
					</Link>
					<Link to="/dados">
						<Button variant="default">Dados públicos</Button>
					</Link>
				</Stack>
			)
		}

		return (
			<Stack direction={{ base: 'column', md: 'column', lg: 'row' }} align="center">
				<Link to="/plan-pipeline">
					<Button variant="default" aria-label="Cadastro do plano">
						Cadastro do plano
					</Button>
				</Link>
				<Link to="/dash">
					<Button variant="default" aria-label="Apoio ao monitoramento">
						Apoio ao monitoramento
					</Button>
				</Link>
				<Link to="/formulario-avaliacao">
					<Button variant="default" aria-label="Formulário de avaliação">
						Formulário de avaliação
					</Button>
				</Link>
				<UserActionsButton />
			</Stack>
		)
	}

	return (
		<FullPageLayout bg="gray.180" shadow="md">
			<Stack
				py={6}
				spacing={6}
				direction={{ base: 'column', sm: 'row' }}
				alignItems={{ base: 'center', sm: 'end' }}
				justifyContent="space-between"
			>
				<ResponsiveLogo />

				<NavButtons />
			</Stack>
		</FullPageLayout>
	)
}
