import { create } from 'zustand'

import { createActionsSlice } from 'modules/actions/store-slice'
import { createPlanSlice, createFileSlice } from 'modules/education-plan/store-slice'
import {
	createEvaluationFormSlice,
	createEvaluationFileSlice,
} from 'modules/evaluation-form/store-slice'
import { createGoalsSlice } from 'modules/goals/store-slice'
import { createStrategiesSlice } from 'modules/strategy/store-slice'

export const useBoundStore = create((...a) => ({
	...createPlanSlice(...a),
	...createGoalsSlice(...a),
	...createStrategiesSlice(...a),
	...createActionsSlice(...a),
	...createFileSlice(...a),
	...createEvaluationFormSlice(...a),
	...createEvaluationFileSlice(...a),
}))
