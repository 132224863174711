import { useMemo } from 'react'

import { Stack, Text, Grid, GridItem, Card, CardBody, Image, Heading, Flex } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { SelectCheckboxField } from 'modules/common/components'
import { TableWithPagination } from 'modules/common/components/CustomTable'

import { graphicList } from '../helpers'

export const SelectGraphicField = () => {
	return (
		<>
			<Text fontSize={'xl'} fontWeight={'bold'}>
				Importar gráficos (Máximo de 10 gráficos)
			</Text>

			<Grid
				templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
				columnGap={8}
				rowGap={8}
				bg={'white'}
				p={8}
			>
				{graphicList.map((item) => (
					<GridItem key={item.id}>
						<GraphicCard graphic={item} />
					</GridItem>
				))}
			</Grid>
			<GraphicTable />
		</>
	)
}

const GraphicTable = () => {
	const selectedGraphics = useBoundStore((state) => state.selectedGraphics)

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			{
				Header: 'GRÁFICO',
				accessor: 'title',
			},
			{
				Header: 'ARQUIVO',
				accessor: 'src',
			},
		],
		[],
	)

	if (!selectedGraphics.length) return
	if (selectedGraphics.length > 10) return

	return <TableWithPagination data={selectedGraphics} columns={columns} />
}

const GraphicCard = ({ graphic }) => {
	const optionsMetas = [
		{
			label: 'Meta 1 - Indicador 1A',
			value: { meta: 1, indicador: '1A', graphic },
		},
		{
			label: 'Meta 1 - Indicador 1B',
			value: { meta: 1, indicador: '1B', graphic },
		},
		{
			label: 'Meta 2 - Indicador 2A',
			value: { meta: 2, indicador: '2A', graphic },
		},
		{
			label: 'Meta 3 - Indicador 3A',
			value: { meta: 3, indicador: '3A', graphic },
		},
		{
			label: 'Meta 3 - Indicador 3B',
			value: { meta: 3, indicador: '3B', graphic },
		},
		{
			label: 'Meta 6 - Indicador 6A',
			value: { meta: 6, indicador: '6A', graphic },
		},
		{
			label: 'Meta 6 - Indicador 6B',
			value: { meta: 6, indicador: '6B', graphic },
		},
	]

	return (
		<Card
			maxW="sm"
			bgColor={graphic.enabled ? 'esmeralda.60' : 'gray.300'}
			borderRadius={12}
			cursor={graphic.enabled ? 'auto' : 'not-allowed'}
			_hover={{ bg: graphic.enabled ? 'esmeralda.80' : 'gray.300' }}
		>
			<CardBody>
				<Flex height={'300px'} bgColor={'white'} borderRadius={8} align={'center'}>
					<Image
						src={graphic.src}
						alt="Green double couch with wooden legs"
						borderRadius="lg"
						opacity={graphic.enabled ? 1 : 0.4}
					/>
				</Flex>
				<Stack mt="6" spacing="3">
					<Heading size="md">{graphic.title}</Heading>
					{graphic.enabled ? (
						<Text>{graphic.description}</Text>
					) : (
						<Text fontWeight={'semibold'}>Disponível em breve</Text>
					)}
					{graphic.enabled && (
						<SelectCheckboxField
							fieldName={`metas${graphic.id}`}
							placeholder={'Selecione uma meta'}
							options={optionsMetas}
						/>
					)}
				</Stack>
			</CardBody>
		</Card>
	)
}
