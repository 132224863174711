export const evaluationPeriodOptions = [
	{
		id: 1,
		title: 'Anual',
		value: 'AN',
	},
	{
		id: 2,
		title: 'Bianual',
		value: 'BI',
	},
	{
		id: 3,
		title: 'Trianual',
		value: 'TR',
	},
	{
		id: 4,
		title: 'Quadrienal',
		value: 'QD',
	},
	{
		id: 5,
		title: 'Quinquenal',
		value: 'QQ',
	},
	{
		id: 6,
		title: 'Semestral',
		value: 'SE',
	},
	{
		id: 7,
		title: 'Trimensal',
		value: 'TM',
	},
	{
		id: 8,
		title: 'Não previsto',
		value: 'NP',
	},
]

export const legalAccountableOptions = [
	{
		label: 'Secretaria Municipal de Educação',
		value: 'secretaria',
	},
	{
		label: 'Comissão de Educação do Poder Legislativo Municipal',
		value: 'comissao',
	},
	{
		label: 'Prefeitura',
		value: 'prefeitura',
	},
]

export const evaluationYearsOptions = [
	{
		label: '2014',
		value: '2014',
	},
	{
		label: '2015',
		value: '2015',
	},
	{
		label: '2016',
		value: '2016',
	},
	{
		label: '2017',
		value: '2017',
	},
	{
		label: '2018',
		value: '2018',
	},
	{
		label: '2019',
		value: '2019',
	},
	{
		label: '2020',
		value: '2020',
	},
	{
		label: '2021',
		value: '2021',
	},
	{
		label: '2022',
		value: '2022',
	},
	{
		label: '2023',
		value: '2023',
	},
	{
		label: '2024',
		value: '2024',
	},
]

export const legalActDescriptionOptions = [
	{
		id: 1,
		title: 'Lei',
		value: 'lei',
	},
	{
		id: 2,
		title: 'Decreto',
		value: 'decreto',
	},
	{
		id: 3,
		title: 'Portaria',
		value: 'portaria',
	},
	{
		id: 4,
		title: 'Resolução',
		value: 'resolucao',
	},
	{
		id: 5,
		title: 'Outro ato normativo',
		value: 'outro',
	},
]
