import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Link, Stack, Button, Text } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'

import { FullPageLayout, TextGradient, ProgressLine } from 'modules/common/components'
import { Form } from 'modules/user/profile/form'

import { createArticulator } from '../usecases'
import { ArticulatorsValidation } from '../validators'

export const ArticulatorsCreate = () => {
	const navigate = useNavigate()
	const validation = ArticulatorsValidation()

	const [, setFoundUser] = useState({})

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
	})

	const { isSubmitSuccessful } = methods.formState

	const cpfValidation = (response) => {
		if (!response.status) return

		const { status, data } = response

		if (status !== 201) {
			const { cpf, non_field_errors } = data
			if (cpf) {
				methods.setError('cpf', { type: 'string', message: 'CPF inválido' })
			}
			if (non_field_errors?.length)
				methods.setError('cpf', { type: 'string', message: 'O usuário já possui um perfil' })
		}
	}

	const onSubmit = async (values) => {
		const { cpf, nome, celular, email, active } = values
		const payload = {
			cpf,
			nome,
			email,
			telefone: celular,
			pflcod: 4,
			pfldsc: 'Articulador',
			status: active ? 'A' : 'I',
			uf: 'PE',
		}

		try {
			const res = await createArticulator(payload)
			cpfValidation(res)
		} catch (err) {
			console.error('GOT ERROR', err)
		}
	}

	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto" />
			<FullPageLayout>
				<ProgressLine />

				<Stack my={10} gap={4}>
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
					>
						Articuladores
					</TextGradient>

					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Stack border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={6}>
								<Form setFoundUser={setFoundUser} hideProfileField />

								<Text pt={4} fontSize="xl">
									Observação: Os campos com * são de preenchimento obrigatório.
								</Text>
							</Stack>

							<Stack direction="row" justify="end" mt={7} mb={16}>
								<Button
									variant="cancel"
									fontSize="lg"
									fontWeight="semibold"
									onClick={() => navigate('./..')}
								>
									Voltar
								</Button>
								<Button
									type="submit"
									variant="defaultHover"
									fontSize="lg"
									fontWeight="semibold"
									isDisabled={isSubmitSuccessful}
								>
									Salvar
								</Button>
							</Stack>
						</form>
					</FormProvider>
				</Stack>
			</FullPageLayout>
		</>
	)
}

export default ArticulatorsCreate
