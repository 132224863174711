import { Stack, Text } from '@chakra-ui/react'

import { TextareaField } from 'modules/common/components'

export const Form = () => {
	return (
		<>
			<Text fontSize={'2xl'} fontWeight={'bold'} mb={12}>
				Descreva a metodologia e organização do monitoramento:
			</Text>
			<Stack spacing={16} mr={16}>
				<TextareaField
					label="Metodologia*"
					fieldName="metodology"
					placeholder="Exemplo: Universalizar, até 2016, a educação infantil na pré-escola para as crianças de 4 (quatro) a 5 (cinco) anos de idade e ampliar a oferta de educação infantil em creches de forma a atender, no mínimo, 50% (cinquenta por cento) das crianças de até 3 (três) anos até o final da vigência deste PNE."
					backgroundColor="white"
					minHeight={36}
					padding={8}
					labelProps={{ fontSize: 'xl' }}
				/>
				<TextareaField
					label="Organização*"
					fieldName="organization"
					placeholder="Exemplo: Universalizar, até 2016, a educação infantil na pré-escola para as crianças de 4 (quatro) a 5 (cinco) anos de idade e ampliar a oferta de educação infantil em creches de forma a atender, no mínimo, 50% (cinquenta por cento) das crianças de até 3 (três) anos até o final da vigência deste PNE."
					backgroundColor="white"
					minHeight={36}
					padding={8}
					labelProps={{ fontSize: 'xl' }}
				/>
			</Stack>

			<Text fontSize={'3xl'} fontWeight={'bold'} mt={16}>
				Município de {'CIDADE'}
			</Text>
			<Stack spacing={6} mt={8} fontSize={{ base: 'md', lg: 'xl' }}>
				<Text>
					O Município de Cidade, situado em região, dispõe de{' '}
					<Text as="span" fontWeight="bold" aria-label="Plano Municipal de Educação (PME)">
						Plano Municipal de Educação (PME){' '}
					</Text>
					atualmente em vigor.
				</Text>

				<Text>
					A legislação que fundamenta o referido PME é a{' '}
					<Text as="span" fontWeight="bold" aria-label="Plano Municipal de Educação (PME)">
						Lei Municipal nº 1.200,{' '}
					</Text>
					com início de vigência datado em 02 de julho de 2015 e término em 02 de julho de 2023.
				</Text>

				<Text>
					O PME em questão estabelece um conjunto de diretrizes a serem seguidas no âmbito da
					educação municipal, abrangendo desde a educação infantil até o ensino superior, com uma
					perspectiva de longo prazo. No contexto das metas delineadas, o município definiu um total
					de 19 metas e 18 estratégias, abordando diversos temas, como qualidade do ensino, formação
					docente, inclusão, infraestrutura escolar e adoção de práticas pedagógicas inovadoras.
				</Text>

				<Text>
					Para avaliação periódica do PME, foi instituído um ciclo anual, reconhecendo a necessidade
					de monitoramento contínuo do progresso e a possibilidade de ajustes nas estratégias
					conforme necessário. A última avaliação, realizada em 2018, proporcionou informações
					relevantes sobre o desempenho do plano, orientando ajustes para otimizar a eficácia das
					ações educacionais.
				</Text>

				<Text>
					A responsabilidade pela avaliação do PME é formalmente designada por meio da{' '}
					<Text as="span" fontWeight="bold" aria-label="Plano Municipal de Educação (PME)">
						Portaria nº 1.200,{' '}
					</Text>
					datada de 02/07/2015. Essa Portaria estabelece a Secretaria de Educação como a instituição
					executora encarregada de conduzir a avaliação do PME, enfatizando a importância atribuída
					a essa entidade no desenvolvimento e acompanhamento das políticas educacionais municipais.
				</Text>

				<Text>
					Além da legislação principal, outros instrumentos legais, como leis sancionadas pelo
					Prefeito, decretos e emendas, contribuem para manter atualizados os dados e premissas do
					PME deste município. Esses documentos formam um conjunto legal coeso, sustentando e
					atualizando as bases do Plano Municipal de Educação.
				</Text>

				<Text>
					O Município de Cidade, estabelecido em ano, tem uma trajetória marcada pela interseção
					entre desenvolvimento econômico e educação. A implementação do primeiro Plano Municipal de
					Educação (PME) ocorreu em 2014, estabelecendo as bases para um compromisso duradouro com o
					avanço educacional. Atualmente, o município segue as diretrizes da Lei Municipal nº 1.200,
					em vigor desde 2015, que apresenta{' '}
					<Text as="span" fontWeight="bold" aria-label="Plano Municipal de Educação (PME)">
						22 metas e 20 estratégias{' '}
					</Text>
					para impulsionar o sistema educacional até 2023.
				</Text>

				<Text>
					Cidade, em sua jornada educacional, confronta desafios significativos, sendo um deles a
					complexidade na coleta de dados para monitorar o Plano Municipal de Educação (PME). A
					obtenção de informações precisas e abrangentes tem sido uma tarefa árdua, impactando a
					capacidade do município de avaliar de forma abrangente o progresso e a eficácia das
					estratégias delineadas.
				</Text>

				<Text>
					A escassez de dados confiáveis constitui um desafio substancial na avaliação anual do PME,
					comprometendo a capacidade de identificar áreas de sucesso e aquelas que exigem ajustes.
					Esta dificuldade pode ser atribuída a fatores como a falta de sistemas integrados de
					coleta de dados e desafios logísticos na obtenção de informações consistentes de todas as
					instituições educacionais.
				</Text>
			</Stack>
			<Text mt={12} fontSize={{ base: 'md', lg: 'xl' }}>
				Observação: Os campos com * são de preenchimento obrigatório.
			</Text>
		</>
	)
}
