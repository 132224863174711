import { useFormContext, useController } from 'react-hook-form'

import { Box, Text, FormControl, FormErrorMessage, Switch } from '@chakra-ui/react'

export const SwitchField = ({ enabledValue, disabledValue, fieldName, ...props }) => {
	const { control } = useFormContext()
	const {
		field,
		fieldState: { error },
	} = useController({
		control,
		name: fieldName,
	})

	return (
		<Box>
			<FormControl isInvalid={!!error}>
				<Box display="flex" alignItems="center" gap={2}>
					<Text>{disabledValue}</Text>
					<Switch
						defaultChecked={field.value}
						value={field.value}
						onChange={(e) => field.onChange(e)}
						{...props}
					/>
					<Text>{enabledValue}</Text>
				</Box>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
		</Box>
	)
}
