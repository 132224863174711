import { Box, Stack } from '@chakra-ui/react'

import { FullPageLayout, TextGradient } from 'modules/common/components'

import AccordionDetails from '../components/AccordionDetails'

const Details = () => {
	return (
		<FullPageLayout>
			<Stack ml={12} mr={12} direction={['column']} justify="space-between">
				<Box py={8}>
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize="4xl"
						fontWeight="bold"
						mb={8}
					>
						Consultar fichas técnicas das metas
					</TextGradient>

					<AccordionDetails />
				</Box>
			</Stack>
		</FullPageLayout>
	)
}
export default Details
