import { useFormContext, useController } from 'react-hook-form'

import { Box, Textarea, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'

export const TextareaField = ({
	label,
	fieldName,
	placeholder,
	maxLength,
	labelProps,
	...props
}) => {
	const { control, register } = useFormContext()
	const {
		field,
		fieldState: { error },
	} = useController({
		control,
		name: fieldName,
	})

	return (
		<Box w="full">
			<FormControl display="flex" gap={[0, 6]} isInvalid={!!error}>
				<FormLabel htmlFor={fieldName} fontWeight="semibold" {...labelProps}>
					{label}
				</FormLabel>
				<Box w="full">
					<Textarea
						ref={register(fieldName).ref}
						placeholder={placeholder}
						_placeholder={{
							fontWeight: 'normal',
							color: 'gray.300',
						}}
						onChange={(e) => field.onChange(e)}
						value={field?.value || undefined}
						maxLength={maxLength}
						{...props}
					/>
					{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
				</Box>
			</FormControl>
		</Box>
	)
}
