import env from '@beam-australia/react-env'
import { Stack } from '@chakra-ui/react'

const Dash = () => {
	const dash_env = env('BI_DASHBOARD')

	return (
		<Stack w="100%" h="100%">
			<iframe src={dash_env} title="teste" allowFullScreen={true} height="2700"></iframe>
		</Stack>
	)
}
export default Dash
