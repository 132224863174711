import { Stack, Link } from '@chakra-ui/react'

import { CardList } from './components/card'

const HomePage = () => {
	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto"></Link>
			<Stack>
				<CardList
					routeSingIn="/plan-pipeline"
					routeMonitoration="/dash"
					routeAvaliation="/formulario-avaliacao"
				/>
			</Stack>
		</>
	)
}

export default HomePage
