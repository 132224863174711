import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Button,
	Image,
	RangeSlider,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	RangeSliderTrack,
	Select,
	Stack,
	Text,
	Tooltip,
} from '@chakra-ui/react'

import IlustracaoDados from 'modules/common/assets/images/IlustracaoDados.png'
import { FullPageLayout, TextGradient } from 'modules/common/components'

import AccordionData from './components/AccordionData'
import DataBar from './components/DataBar'
import DataTable from './components/DataTable'
import { getGoalsYear } from './usecases/get-goals'

const Data = () => {
	const [value, setValue] = useState([2016, 2019])
	const [goal, setGoal] = useState('')
	const [showTable, setShowTable] = useState(false)
	const navigate = useNavigate()
	const handleSelectChange = (event) => {
		const selectedValue = event.target.value
		setGoal(selectedValue)
	}
	const handleExportYear = async () => {
		const dataExport = await getGoalsYear(goal, value[0], value[1])
		console.log(dataExport)
		setShowTable(true)
	}
	const handleExport = () => {
		// exportData()
	}

	return (
		<FullPageLayout>
			<Stack mt={8} ml={12} mr={12} direction={['column']} justify="space-between">
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
				>
					Conecta PNE: Portal de Dados Abertos
				</TextGradient>
				<DataBar />
				<Stack direction={{ base: 'column', lg: 'row' }} align="center">
					<Text fontSize="2xl">
						Boas-vindas à seção de dados abertos da Conecta PNE. Esta área fornece acesso às bases
						de dados que fundamentam as metas apresentadas em nosso portal. Estas informações são
						vitais para compreender os avanços e desafios enfrentados para a execução do Plano
						Nacional da Educação.
					</Text>
					<Image src={IlustracaoDados} alt="Imagem ilustrada de pessoas" w={600} h={400} />
				</Stack>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontWeight="bold"
					fontSize="3xl"
					mb={4}
				>
					Principais Fontes de Dados
				</TextGradient>
				<Stack>
					<Stack direction={['row']}>
						<Text fontSize="2xl" fontWeight="bold" ml={8}>
							* INEP:{' '}
						</Text>
						<Text fontSize="2xl">
							provê detalhes sobre o setor educacional. populacionais e dados socioeconômicos.
						</Text>
					</Stack>
					<Stack direction={['row']}>
						<Text fontSize="2xl" fontWeight="bold" ml={8}>
							* IBGE:{' '}
						</Text>
						<Text fontSize="2xl">
							responsável pelas estimativas populacionais e dados socioeconômicos.
						</Text>
					</Stack>
					<Stack direction={['row']}>
						<Text fontSize="2xl" fontWeight="bold" ml={8}>
							* DataSUS:{' '}
						</Text>
						<Text fontSize="2xl">
							dados demográficos baseados em registros de nascimentos e óbitos.
						</Text>
					</Stack>
					<Stack direction={['row']}>
						<Text fontSize="2xl" fontWeight="bold" ml={8}>
							* Geocapes:{' '}
						</Text>
						<Text fontSize="2xl">informações sobre titulações de mestrado e doutorado.</Text>
					</Stack>
					<Stack direction={['row']}>
						<Text fontSize="2xl" fontWeight="bold" ml={8}>
							* Siconfi:{' '}
						</Text>
						<Text fontSize="2xl">dados contábeis e fiscais.</Text>
					</Stack>
				</Stack>
				<Box></Box>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
					mt={8}
					mb={4}
				>
					Categoria de dados disponíveis
				</TextGradient>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					gap="12px"
				>
					<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" w={770} h={213}>
						<Box
							direction="row"
							display="flex"
							alignItems="center"
							justifyContent="center"
							w={770}
							h={213}
						>
							<TextGradient
								fromColor="esmeralda.100"
								toColor="marinho.100"
								fontSize="5xl"
								mr={8}
								alignItems="center"
								display="flex"
								ml={8}
							>
								1
							</TextGradient>
							<Text fontSize="2xl">
								Metas e Indicadores: Consulte os resultados das metas e indicadores, que podem ser
								filtrados por ano.
							</Text>
						</Box>
					</Box>
					<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" w={770} h={213}>
						<Box
							direction="row"
							display="flex"
							alignItems="center"
							justifyContent="center"
							w={770}
							h={213}
						>
							<TextGradient
								fromColor="esmeralda.100"
								toColor="marinho.100"
								fontSize="5xl"
								mr={8}
								alignItems="center"
								display="flex"
								ml={8}
							>
								2
							</TextGradient>
							<Text fontSize="2xl" mr={2}>
								Microdados públicos: Acesse dados que foram preliminarmente tratados para
								fundamentar o cálculo das metas mostradas no portal.
							</Text>
						</Box>
					</Box>
				</Box>
				<Text fontSize="2xl" mt={4}>
					Adicionalmente, disponibilizamos documentação completa das bases de dados e fichas
					técnicas, as quais detalham os métodos de cálculo de cada indicador. Esse material é
					crucial para assegurar transparência e facilitar a compreensão dos dados que direcionam
					nossas metas educacionais.
				</Text>
				<Text fontSize="2xl" mt={2} mb={12}>
					Convidamos você a explorar estas informações. Em caso de dúvidas, consulte a documentação
					e fichas técnicas. Nosso compromisso é manter um portal acessível e informativo,
					promovendo a transparência e o envolvimento público no desenvolvimento da educação
					brasileira.
				</Text>
				<Stack direction={'row'} align="center">
					<Box
						w={570}
						h={195}
						bg="#3F9E6E"
						color="white"
						borderRadius={8}
						alignContent="center"
						onClick={() => navigate('./dicionario')}
					>
						<Text fontSize="5xl" fontWeight="bold" w={470} ml={10}>
							Explorar Dicionário de Dados
						</Text>
					</Box>
					<Box
						w={570}
						h={195}
						bg="#3F9E6E"
						color="white"
						borderRadius={8}
						alignContent="center"
						onClick={() => navigate('./detalhes')}
					>
						<Text fontSize="5xl" fontWeight="bold" w={470} ml={10}>
							Consultar Fichas Técnicas das Metas
						</Text>
					</Box>
				</Stack>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
					mt={8}
					mb={4}
				>
					Metas e indicadores
				</TextGradient>
				<Text fontSize="2xl" mt={2} mb={8}>
					Encontre aqui os indicadores atualizados para cada meta estabelecida pelo Plano Nacional
					de Educação (PNE). Detalhes e fórmulas específicas estão disponíveis na respectiva ficha
					técnica de cada meta.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" mb={2}>
					Escolha a Meta de Interesse:
				</Text>

				<Select
					placeholder="Escolha uma meta"
					size="lg"
					mb={8}
					onChange={handleSelectChange}
					value={goal}
				>
					<option value="FCT_META_UM">Meta 1 - Educação Infantil</option>
					<option value="FCT_META_DOIS">Meta 2 - Ensino Fundamental</option>
					<option value="FCT_META_TRES">Meta 3 - Ensino Médio</option>
					{/* <option value="4">Meta 4 - Inclusão</option> */}
					<option value="FCT_META_SEIS">Meta 6 - Educação Integral</option>
					{/* <option value="6">Meta 7 - Qualidade da Educação Básica/IDEB</option>
					<option value="6">Meta 9 - Alfabetização de jovens e adultos</option> */}
					<option value="FCT_META_DEZ">Meta 10 - EJA Integrada</option>
					<option value="FCT_META_ONZE">Meta 11 - Educação Profissional Técnica</option>
					{/* <option value="12">Meta 12 - Educação Superior</option> */}
					<option value="FCT_META_TREZE">Meta 13 - Ensino Superior</option>
					<option value="FCT_META_QUATORZE">Meta 14 - Ensino Superior</option>
					{/* <option value="14">Meta 15 - Profissionais de Educação</option> */}
					<option value="FCT_META_DEZESSEIS">Meta 16 - Formação</option>
					{/* <option value="14">Meta 17 - Valorização dos Profissionais do Magistério</option>
					<option value="14">Meta 18 - Planos de Carreira</option>
					<option value="16">Meta 19 - Gestão Democrática</option>
					<option value="20">Meta 20 - Financiamento da Educação</option> */}
				</Select>

				<Text fontSize="2xl" fontWeight="bold" mb={8}>
					Defina o Período de Análise:
				</Text>
				<RangeSlider
					aria-label={['min', 'max']}
					defaultValue={[2016, 2020]}
					min={2010}
					max={2024}
					color="#3f9e6e"
					step={1}
					onChange={(val) => setValue(val)}
					mb={8}
				>
					<RangeSliderTrack>
						<RangeSliderFilledTrack />
					</RangeSliderTrack>
					<RangeSliderThumb index={0}>
						<Tooltip
							hasArrow
							bg="white"
							color="black"
							placement="top"
							isOpen={true}
							label={value[0]}
						>
							<Box color="white" />
						</Tooltip>
					</RangeSliderThumb>
					<RangeSliderThumb index={1}>
						<Tooltip
							hasArrow
							bg="white"
							color="black"
							placement="top"
							isOpen={true}
							label={value[1]}
						>
							<Box color="white" />
						</Tooltip>
					</RangeSliderThumb>
				</RangeSlider>
				<Text fontSize="2xl" fontWeight="bold" mb={2}>
					Visualize uma Amostra dos Dados:
				</Text>
				<Text fontSize="2xl">
					Confira abaixo uma amostra dos dados para a meta selecionada, detalhando informações como
					ID da meta, ano, código do estado, código do município, grau acadêmico e indicadores.
				</Text>
				{showTable && <DataTable />}
				<Button
					mt={2}
					mb={4}
					bg="#3F9E6E"
					color="white"
					w="135px"
					h="38px"
					onClick={handleExportYear}
				>
					<Text fontSize="1xl">Exporte os dados</Text>
				</Button>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
					mt={8}
					mb={4}
				>
					Microdados públicos
				</TextGradient>
				<Text fontSize="2xl">
					Esta seção provê os microdados fundamentais para o cálculo das metas do Plano Nacional de
					Educação. Para entender como os dados são utilizados ou para obter mais detalhes, consulte
					a ficha técnica de cada meta.
				</Text>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="3xl"
					mt={8}
					mb={4}
					fontWeight="bold"
				>
					Categorias de microdados
				</TextGradient>
				<Text fontSize="2xl">
					Educação: Inclui bases de dados específicas do setor educacional. Populacional: Contém
					dados que suportam projeções populacionais e outras informações geográficas. Outros Dados
					Públicos: Abrange microdados relacionados a indicadores socioeconômicos e faixas de renda.
				</Text>
				<Text fontSize="2xl" fontWeight="bold">
					Seleção de Dados:
				</Text>
				<Select placeholder="Categoria" size="lg">
					<option value="1">Educacional</option>
					<option value="2">Populacional</option>
					<option value="3">Outros dados públicos</option>
				</Select>
				<Select placeholder="Base de dados específica" size="lg">
					<option value="1">Censo da Educação Básica</option>
					<option value="2">Censo da Educação Superior</option>
				</Select>
				<Button mt={4} mb={8} bg="#3F9E6E" color="white" w="135px" h="38px" onClick={handleExport}>
					<Text fontSize="1xl">Exporte os dados</Text>
				</Button>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					mt={8}
					mb={12}
					fontWeight="bold"
				>
					Dúvidas frequentes
				</TextGradient>
				<AccordionData />
			</Stack>
		</FullPageLayout>
	)
}
export default Data
