const { TableContainer, Table, Thead, Tr, Th, Tbody, Td } = require('@chakra-ui/react')

const DictionaryTable = (table) => {
	return (
		<TableContainer mt={8} mb={8} bg="#FFFFFF01" borderColor="#E2E8F0">
			<Table variant="simple" borderColor="#E2E8F0">
				<Thead>
					<Tr>
						<Th bg="#F7FAFC">Nome da variável</Th>
						<Th bg="#F7FAFC">Descrição da Variável</Th>
						<Th bg="#F7FAFC">Fonte da Variável</Th>
						{/* <Th bg="#F7FAFC">Categoria</Th>
						<Th bg="#F7FAFC">Observação</Th> */}
					</Tr>
				</Thead>
				<Tbody>
					{table?.table?.ind?.map((element) => {
						return element?.table?.map((item) => {
							return (
								<Tr key={item.column}>
									<Td>{item.column}</Td>
									<Td>{item.desColumn}</Td>
									<Td>{item.font}</Td>
									{/* <Td>{element.titleInd}</Td>
									<Td>{item.font}</Td> */}
								</Tr>
							)
						})
					})}
				</Tbody>
			</Table>
		</TableContainer>
	)
}
export default DictionaryTable
