import jwtDecode from 'jwt-decode'

export const decode = (token) => {
	return jwtDecode(token)
}

export const isValid = (exp) => new Date() < getExpirationDate(exp)

export const validateTokens = () => {
	const { accessToken, refreshToken } = get()
	const result = {
		accessToken,
		refreshToken,
		isAccessValid: false,
		isRefreshValid: false,
	}

	if (accessToken && refreshToken) {
		const decodedAccess = decode(accessToken)
		const decodedRefresh = decode(refreshToken)
		result.isAccessValid = isValid(decodedAccess.exp)
		result.isRefreshValid = isValid(decodedRefresh.exp)
	}

	return result
}

const getExpirationDate = (exp) => new Date(exp * 1000)

const get = () => {
	const {
		state: { accessToken, refreshToken },
	} = JSON.parse(sessionStorage.getItem('user-store'))

	return { accessToken, refreshToken }
}
