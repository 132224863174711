import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Button, Text, Flex } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBoundStore } from 'store'

import { FullPageLayout, ProgressLine, TextGradient } from 'modules/common/components'

import { Form } from './form'
import { createAction } from './usecases'
import { ActionsValidation } from './validators'

export const ActionsForm = () => {
	const navigate = useNavigate()
	const validation = ActionsValidation()

	const resetSelectedStrategies = useBoundStore((state) => state.resetSelectedStrategies)
	const selectedStrategies = useBoundStore((state) => state.selectedStrategies)

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
		defaultValues: {
			strategies: selectedStrategies || null,
		},
	})

	const { isValid, isSubmitSuccessful } = methods.formState

	const handleResponse = (response) => {
		if (!response.status) {
			const state = {
				message: `Ação adicionada ao Plano Municipal de Educação!`,
				status: 'success',
			}
			return state
		}

		return response
	}

	const onSubmit = async (values) => {
		const { strategies, actionDescription, actionNumber } = values

		const estrategia = strategies.map((strategy) => {
			if (strategy.value) return strategy.value
			return parseInt(strategy)
		})

		const payload = {
			numero: parseInt(actionNumber),
			descricao: actionDescription,
			estrategia,
		}

		try {
			let state

			const response = await createAction(payload)
			if (response) {
				state = handleResponse(response)
				resetSelectedStrategies()
				navigate('./..', { state })
			}
		} catch (err) {
			console.error('GOT ERROR', err)
		}
	}

	const handleCancel = () => {
		resetSelectedStrategies()
		navigate('./..')
	}

	return (
		<FullPageLayout>
			<ProgressLine />

			<Stack my={8} gap={4}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
				>
					Metas / Estratégias / Ações
				</TextGradient>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={4}>
							<Form />
						</Box>
						<Stack direction="row" justify="space-between" mt={7}>
							<Text>Observação: Os campos com * são de preenchimento obrigatório.</Text>
							<Flex gap={3}>
								<Button variant="cancel" fontWeight="semibold" onClick={handleCancel}>
									Cancelar
								</Button>
								<Button
									type="submit"
									variant="defaultHover"
									fontWeight="semibold"
									isDisabled={!isValid || isSubmitSuccessful}
								>
									Salvar
								</Button>
							</Flex>
						</Stack>
					</form>
				</FormProvider>
			</Stack>
		</FullPageLayout>
	)
}
