import { Stack, Text } from '@chakra-ui/react'

import { InputField, SelectField, SwitchField } from 'modules/common/components'

import { EndingDate, Evaluation, Field, ToggleSwitch, UploadField } from './components'
import { evaluationPeriodOptions, planValidity } from './helpers'

export const Form = () => {
	return (
		<ToggleSwitch
			label="O ente possui Plano Subnacional vigente?"
			warning="Ao selecionar que o Município não tem Plano de Educação vigente, você não poderá avançar no preenchimento do sistema e seus benefícios."
		>
			<Fields />
		</ToggleSwitch>
	)
}

const Fields = () => {
	return (
		<Stack mt={6} spacing={5}>
			<Field
				label="Lei que aprova o Plano Municipal de Educação (PME)"
				textAsLabel
				w="full"
				justify="space-between"
			>
				<InputField
					label="Número*"
					fieldName="lawNumber"
					placeholder="Ex: 1.200"
					w="max-content"
					display="flex"
					alignItems="center"
				/>

				<InputField
					label="Data de início de vigência*"
					fieldName="initialDate"
					placeholder="Ex: 02/07/2015"
					display="flex"
					alignItems="center"
					inputType="date"
					inputProps={{ min: planValidity.min, max: planValidity.max }}
				/>

				<EndingDate />
			</Field>

			<Field label="Quantidade de metas que o PME possui *">
				<InputField w="fit-content" fieldName="goalsQnt" placeholder="Ex: 19" />
			</Field>

			<Field label="Quantidade de estratégias que o PME possui *">
				<InputField w="fit-content" fieldName="stratQnt" placeholder="Ex: 19" />
			</Field>

			<Field label="Período de avaliação previsto *">
				<SelectField
					fieldName="evaluationPeriod"
					options={evaluationPeriodOptions}
					placeholder="Selecione o formato de avaliação"
					w="fit-content"
				/>
			</Field>

			<Field label="Houve avaliação? *">
				<SwitchField
					enabledValue="Sim"
					disabledValue="Não"
					fieldName="evaluationHappened"
					colorScheme="green"
				/>
			</Field>

			<Evaluation />

			<UploadField />

			<Text>Observação: Os campos com * são de preenchimento obrigatório.</Text>
		</Stack>
	)
}
