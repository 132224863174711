import { object, array, string, boolean, number, date } from 'yup'

export const PlanValidation = () =>
	object({
		lawNumber: number().required('Campo obrigatório').typeError('Preencha apenas com números'),
		initialDate: date().typeError('Insira uma data válida').required('Campo obrigatório'),
		endingDate: date()
			.when('initialDate', (initialDate) => {
				return date()
					.typeError('Insira uma data válida')
					.test({
						test: (value) => {
							if (value) {
								const initial = new Date(initialDate)
								const ending = new Date(value)
								if (ending.getTime() < initial.getTime()) return false
								return true
							}
							return false
						},
						message: 'Deve ser uma data posterior a data de início',
					})
			})
			.typeError('Insira uma data válida')
			.required('Campo obrigatório'),
		goalsQnt: number().typeError('Preencha apenas com números').required('Campo obrigatório'),
		stratQnt: number().typeError('Preencha apenas com números').required('Campo obrigatório'),
		evaluationPeriod: string().required('Campo obrigatório'),
		evaluationHappened: boolean().required('Campo obrigatório'),
		evaluationYears: array()
			.required('Campo obrigatório')
			.test({
				test: (array) => array.length > 0,
				message: 'Selecione pelo menos 1 ano',
			}),
		legalActResponsible: array()
			.required('Campo obrigatório')
			.test({
				test: (array) => array.length > 0,
				message: 'Selecione pelo menos 1 responsável',
			}),
		legalActDescription: string().required('Campo obrigatório'),
		legalActNumber: number().typeError('Preencha apenas com números').required('Campo obrigatório'),
		legalActDate: date().typeError('Insira uma data válida').required('Campo obrigatório'),
		files: array()
			.required('Envie um arquivo PDF.')
			.test({
				test: (array) => array.length > 0,
				message: 'Selecione um arquivo',
			}),
	})
