import { Stack, Text } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { InputField, SelectField, TextareaField } from 'modules/common/components'

export const Form = () => {
	const goals = useBoundStore((state) => state.goals)

	const options = goals
		.sort(function (a, b) {
			return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
		})
		.map((item) => ({
			id: item.id,
			title: 'Meta ' + item.numero,
			value: item.id,
		}))

	return (
		<Stack spacing={4}>
			<Text color="gray.900" fontSize="xl" fontWeight="bold">
				Adicionar Estratégia
			</Text>

			<SelectField
				label="Meta Associada*"
				fieldName="meta_subnacional"
				placeholder="Selecione uma meta"
				options={options}
				w={{ base: 'full', lg: 'fit-content' }}
				display={{ base: 'block', lg: 'flex' }}
				alignItems="center"
				gap={{ base: 0, lg: 16 }}
			/>

			<TextareaField
				label="Descrição da estratégia*"
				fieldName="descricao"
				placeholder="Exemplo: Exemplo: Realizar, periodicamente, em regime de colaboração, levantamento da demanda por creche para a população de até 3 (três) anos, como forma de planejar a oferta e verificar o atendimento da demanda manifesta;"
				backgroundColor="white"
				width="75%"
				marginLeft={3}
			/>

			<InputField
				label="Número da Estratégia*"
				fieldName="numero"
				placeholder="Exemplo: 120"
				display={{ base: 'block', lg: 'flex' }}
				alignItems="center"
				gap={{ base: 0, lg: 5 }}
			/>
		</Stack>
	)
}
