import fetch from 'config/api'

export const getTermo = async (id) => {
	return fetch({
		url: `/ta_resposta/${id}/`,
		method: 'GET',
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('request termo error', err))
}
