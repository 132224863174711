import { useDropzone } from 'react-dropzone'
import { AiOutlineArrowDown } from 'react-icons/ai'

import { Button } from '@chakra-ui/react'

export const UploadZone = ({ onDrop, onCancel, btnLabel, ...btnProps }) => {
	const { getRootProps } = useDropzone({
		noDrag: true,
		onDrop,
		onFileDialogCancel: onCancel,
		accept: { 'application/pdf': ['.pdf'] },
	})

	return (
		<Button
			leftIcon={<AiOutlineArrowDown />}
			{...getRootProps({ className: 'dropzone' })}
			{...btnProps}
		>
			{btnLabel}
		</Button>
	)
}
