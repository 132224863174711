import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AiFillExclamationCircle } from 'react-icons/ai'
import { IoCloseOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Button, Text, Center, Flex, Icon, IconButton } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBoundStore } from 'store'

import { FullPageLayout, TextGradient, StepsLine } from 'modules/common/components'

import { generatePdf } from '../usecases'
import { GoalsAndIndicatorsValidation } from '../validators'
import { Form } from './form'
import { OtherInfo } from './other-info'

export const GoalsAndIndicators = () => {
	const navigate = useNavigate()
	const validation = GoalsAndIndicatorsValidation()
	const [onSizeLimit, setOnSizeLimit] = useState(false)
	let graficosTotal = 0
	const formData = useBoundStore((state) => state.formData)
	const setPdfUrl = useBoundStore((state) => state.setPdfUrl)
	const foreseenGoals = useBoundStore((state) => state.foreseenGoals)
	const implementedGoals = useBoundStore((state) => state.implementedGoals)
	const [showToast, setShowToast] = useState(false)
	const closeToast = () => {
		setShowToast(false)
	}
	const methods = useForm({
		mode: 'onChange',
		defaultValues: formData,
		resolver: yupResolver(validation),
	})

	const handleSizeGraph = async (values) => {
		graficosTotal = 0
		if (values.metas1) {
			graficosTotal = graficosTotal + values?.metas1?.length
			console.log('meta 1 size', graficosTotal)
		}
		if (values.metas2) {
			graficosTotal = graficosTotal + values?.metas2?.length
			console.log('meta 2 size', graficosTotal)
		}
		if (values.metas3) {
			graficosTotal = graficosTotal + values?.metas3?.length
			console.log('meta 3 size', graficosTotal)
		}
		if (values.metas4) {
			graficosTotal = graficosTotal + values?.metas4?.length
			console.log('meta 4 size', graficosTotal)
		}
		if (graficosTotal > 10) {
			setShowToast(true)
			setOnSizeLimit(false)
		} else {
			setOnSizeLimit(true)
		}
	}

	const handleGenerateReport = async (values) => {
		const ano_indice_previsto = foreseenGoals?.map((element) => ({
			ano: element.foreseenGoalYear,
			indice: element.foreseenGoalIndice,
		}))
		const ano_indice_executado = implementedGoals?.map((element) => ({
			ano: element.implementedGoalYear,
			indice: element.implementedGoalIndice,
		}))

		const comp_indicador = values?.files?.map((file) => ({
			nome_arquivo: file.name,
			arquivo: file,
		}))

		let info_graficos = []
		if (values.metas1) {
			const data = values.metas1.map(({ value: element }) => ({
				id: element.graphic.id,
				secao: element.graphic.secao,
				indicador: element.indicador,
			}))
			info_graficos = [...info_graficos, ...data]
		}
		if (values.metas2) {
			const data = values.metas2.map(({ value: element }) => ({
				id: element.graphic.id,
				secao: element.graphic.secao,
				indicador: element.indicador,
			}))
			info_graficos = [...info_graficos, ...data]
		}
		if (values.metas3) {
			const data = values.metas3.map(({ value: element }) => ({
				id: element.graphic.id,
				secao: element.graphic.secao,
				indicador: element.indicador,
			}))
			info_graficos = [...info_graficos, ...data]
		}
		if (values.metas4) {
			const data = values.metas4.map(({ value: element }) => ({
				id: element.graphic.id,
				secao: element.graphic.secao,
				indicador: element.indicador,
			}))
			info_graficos = [...info_graficos, ...data]
		}

		const payload = {
			metodologia: formData?.metodology,
			organizacao: formData?.organization,
			descricao_indicador: values?.indicatorDescription,
			ano_indice_previsto,
			ano_indice_executado,
			equipes: values?.teamDescription,
			info_graficos,
			infos: values?.otherInfo,
			comp_indicador,
		}

		try {
			const url = await generatePdf(payload)
			if (url) setPdfUrl(url)
		} catch (err) {
			console.error(err)
		}
	}

	const onSubmit = async (values) => {
		await handleSizeGraph(values)
		console.log('on submit: ', onSizeLimit)
		if (onSizeLimit) {
			await handleGenerateReport(values)
			navigate('../gerar-relatorio')
		}
	}

	const steps = [
		{ title: 'Contextualização', path: '/formulario-avaliacao' },
		{ title: 'Metas e Indicadores', path: '/formulario-avaliacao/metas-e-indicadores' },
		{ title: 'Geração de Relatório', path: '/formulario-avaliacao/gerar-relatorio' },
	]

	return (
		<FullPageLayout>
			<StepsLine steps={steps} />
			<TextGradient
				fromColor="esmeralda.100"
				toColor="marinho.100"
				fontSize="5xl"
				fontWeight="bold"
				my={8}
			>
				Formulário de Avaliação
			</TextGradient>

			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Stack spacing={12}>
						<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={14}>
							<Form />
						</Box>

						<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={14}>
							<OtherInfo />
						</Box>
					</Stack>
					{!onSizeLimit ? <Text fontWeight={'semibold'}>Limite de gráficos atingido!</Text> : <></>}
					{showToast && (
						<Center mt={8}>
							<Flex
								borderRadius="xl"
								bg="red"
								color="white"
								w="max-content"
								alignItems="center"
								gridGap={4}
								py={2}
								px={4}
							>
								<Icon as={AiFillExclamationCircle} boxSize={5} />
								<Flex gridGap={1}>
									<Text
										fontSize="sm"
										fontWeight="bold"
										style={{
											whiteSpace: 'normal',
											wordWrap: 'break-word',
										}}
									>
										Limite de gráficos atingido. Máximo de 10 gráficos.
									</Text>
								</Flex>
								<IconButton
									px={0}
									variant="ghost"
									_hover={{ bg: 'none' }}
									icon={<Icon as={IoCloseOutline} boxSize={5} fontWeight="bold" />}
									onClick={closeToast}
								/>
							</Flex>
						</Center>
					)}
					<Stack direction="row" justify="space-between" mt={7} mb={16}>
						<Button variant={'defaultHover'} onClick={() => navigate('..')}>
							Retornar
						</Button>
						<Button
							type="submit"
							variant="defaultHover"
							fontWeight="semibold"
							// isDisabled={isSubmitSuccessful}
						>
							Salvar/Avançar
						</Button>
					</Stack>
				</form>
			</FormProvider>
		</FullPageLayout>
	)
}
