import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Button } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBoundStore } from 'store'

import { FullPageLayout, StepsLine, TextGradient } from 'modules/common/components'

import { Form } from './form'
import { EvaluationContextValidation } from './validators'

export const EvaluationForm = () => {
	const navigate = useNavigate()
	const validation = EvaluationContextValidation()

	const formData = useBoundStore((state) => state.formData)
	const setFormData = useBoundStore((state) => state.setFormData)

	const methods = useForm({
		mode: 'onChange',
		defaultValues: formData,
		resolver: yupResolver(validation),
	})

	const { isSubmitSuccessful } = methods.formState

	const onSubmit = (values) => {
		setFormData(values)
		navigate('./metas-e-indicadores')
	}

	const steps = [
		{ title: 'Contextualização', path: '/formulario-avaliacao' },
		{ title: 'Metas e Indicadores', path: '/formulario-avaliacao/metas-e-indicadores' },
		{ title: 'Geração de Relatório', path: '/formulario-avaliacao/gerar-relatorio' },
	]

	return (
		<>
			<FullPageLayout>
				<StepsLine steps={steps} />
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="5xl"
					fontWeight="bold"
					my={8}
				>
					Formulário de Avaliação
				</TextGradient>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" py={8} px={4}>
							<Form />
						</Box>

						<Stack direction="row" justify="end" mt={7} mb={16}>
							<Button
								type="submit"
								variant="defaultHover"
								fontWeight="semibold"
								isDisabled={isSubmitSuccessful}
							>
								Salvar/Avançar
							</Button>
						</Stack>
					</form>
				</FormProvider>
			</FullPageLayout>
		</>
	)
}
