import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { ActionsForm } from 'modules/actions/actions-form'
import { Articulators } from 'modules/articulators'
import ArticulatorsCreate from 'modules/articulators/create'
import { OutletLayout } from 'modules/common/components'
import { ScrollToTop } from 'modules/common/components/ScrollToTop'
import Dash from 'modules/dash'
import Data from 'modules/data-page'
import Details from 'modules/data-page/details'
import Dicionary from 'modules/data-page/dicionary'
import { EducationPlanList } from 'modules/education-plan'
import { EducationPlanForm } from 'modules/education-plan/education-plan-form'
import { PlanPipeline } from 'modules/education-plan/pipeline'
import { EvaluationForm } from 'modules/evaluation-form'
import { GenerateReport } from 'modules/evaluation-form/generate-report'
import { GoalsAndIndicators } from 'modules/evaluation-form/goals-and-indicators'
import { GoalsStrategiesActions } from 'modules/goals-strategies-actions'
import { GoalsForm } from 'modules/goals/goals-form'
import Home from 'modules/home'
import LandingPage from 'modules/landing-page'
import LoginRedirect from 'modules/login'
import { ProfileManagement } from 'modules/profile-management'
import { StrategiesForm } from 'modules/strategy/strategies-form'
import { TermoAdesao } from 'modules/user'
import { ProfileForm } from 'modules/user/profile'
import { Roles } from 'modules/user/roles'

import { PrivateRoute } from './private-route'
import { RequirePermission } from './require-permission'
import { RouteGuard } from './route-guard'

const articulatorsAllowed = [Roles.SUPER, Roles.MEC, Roles.GESTOR, Roles.ARTICULADOR]
const planAllowed = [Roles.SUPER, Roles.MEC, Roles.GESTOR, Roles.ARTICULADOR]
const gsaAllowed = [Roles.SUPER, Roles.MEC, Roles.GESTOR, Roles.ARTICULADOR]
const evaluationAllowed = [Roles.SUPER, Roles.MEC, Roles.GESTOR, Roles.ARTICULADOR]
const profileAllowed = [Roles.SUPER, Roles.MEC]

const Router = () => {
	return (
		<BrowserRouter>
			<ScrollToTop>
				<Routes>
					<Route path="/login" element={<LoginRedirect />} />

					<Route path="/*" element={<OutletLayout />}>
						{/* Public routes */}
						<Route index element={<LandingPage />} />
						<Route path="dash" element={<Dash />} />
						<Route path="dados">
							<Route index element={<Data />} />
							<Route path="dicionario" element={<Dicionary />} />
							<Route path="detalhes" element={<Details />} />
						</Route>

						<Route
							path="termo-adesao"
							element={
								<RouteGuard>
									<TermoAdesao />
								</RouteGuard>
							}
						/>

						{/* Auth-protected (private) routes */}
						<Route element={<PrivateRoute />}>
							<Route path="home" element={<Home />} />
							<Route path="plan-pipeline" element={<PlanPipeline />} />

							{/* TODO change route path to ptBR */}

							<Route element={<RequirePermission allowedRoles={articulatorsAllowed} />}>
								<Route path="articulators">
									<Route index element={<Articulators />} />
									<Route path="create" element={<ArticulatorsCreate />} />
								</Route>
							</Route>

							<Route element={<RequirePermission allowedRoles={planAllowed} />}>
								<Route path="plano-de-educacao">
									<Route index element={<EducationPlanList />} />
									<Route path="edit" element={<EducationPlanForm />} />
								</Route>
							</Route>

							<Route element={<RequirePermission allowedRoles={gsaAllowed} />}>
								<Route path="metas-estrategias-acoes">
									<Route index element={<GoalsStrategiesActions />} />
									<Route path="cadastrar-meta" element={<GoalsForm />} />
									<Route path="cadastrar-estrategia" element={<StrategiesForm />} />
									<Route path="cadastrar-acao" element={<ActionsForm />} />
								</Route>
							</Route>

							<Route element={<RequirePermission allowedRoles={evaluationAllowed} />}>
								<Route path="formulario-avaliacao">
									<Route index element={<EvaluationForm />} />
									<Route path="metas-e-indicadores" element={<GoalsAndIndicators />} />
									<Route path="gerar-relatorio" element={<GenerateReport />} />
								</Route>
							</Route>

							<Route element={<RequirePermission allowedRoles={profileAllowed} />}>
								<Route path="gestao-de-perfis">
									<Route index element={<ProfileManagement />} />
									<Route path="cadastrar" element={<ProfileForm />} />
								</Route>
							</Route>
						</Route>
					</Route>
				</Routes>
			</ScrollToTop>
		</BrowserRouter>
	)
}

export default Router
