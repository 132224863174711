import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Box, Stack, Button, Text, Flex } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBoundStore } from 'store'

import { FullPageLayout, ProgressLine, TextGradient } from 'modules/common/components'

import { Form } from './form'
import { createGoal, getNationalGoals } from './usecases'
import { GoalsValidation } from './validators'

export const GoalsForm = () => {
	const navigate = useNavigate()
	const validation = GoalsValidation()
	const setGoals = useBoundStore((state) => state.setNationalGoals)
	const goals = useBoundStore((state) => state.nationalGoals)
	const { id: planId } = useBoundStore((state) => state.plan)

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
	})

	const { isValid, isSubmitSuccessful } = methods.formState

	const handleResponse = (response) => {
		if (!response.status) {
			const state = {
				message: `Meta número ${response.numero} adicionada ao Plano Municipal de Educação!`,
				status: 'success',
				link: {
					show: true,
					action: 'Ir para o',
					destiny: 'cadastro de uma estratégia.',
					to: '/metas-estrategias-acoes/cadastrar-estrategia',
				},
			}
			return state
		}

		return response
	}

	const onSubmit = async (values) => {
		const { goalNumber, goalDescription, nationalGoals, indicators } = values

		const meta_nacional = nationalGoals.map((goal) => parseInt(goal))

		const payload = {
			numero: goalNumber,
			descricao: goalDescription,
			meta_nacional,
			planosubnacional: planId,
		}

		const payloadWithIndicators = {
			numero: parseInt(goalNumber),
			descricao: goalDescription,
			meta_nacional,
			indicadores: indicators,
			planosubnacional: planId,
		}

		try {
			let state

			if (indicators.length) {
				const res = await createGoal(payloadWithIndicators)
				state = handleResponse(res)
			} else {
				const res = await createGoal(payload)
				state = handleResponse(res)
			}

			navigate('./..', { state })
		} catch (err) {
			console.error('GOT ERROR', err)
		}
	}

	useEffect(() => {
		if (goals.length) return

		const fetchGoals = async () => {
			const { results } = await getNationalGoals()
			if (results) {
				setGoals(results)
			}
		}
		fetchGoals()
	}, [goals])

	return (
		<FullPageLayout>
			<ProgressLine />

			<Stack my={8} gap={4}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="4xl"
					fontWeight="bold"
				>
					Metas / Estratégias / Ações
				</TextGradient>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box border="1px" borderRadius="3xl" layerStyle="gradientBorder" p={4}>
							<Form />
							<Stack direction="row" justify="space-between" mt={7}>
								<Text>Observação: Os campos com * são de preenchimento obrigatório.</Text>
								<Flex gap={3}>
									<Button variant="cancel" fontWeight="semibold" onClick={() => navigate('./..')}>
										Cancelar
									</Button>
									<Button
										type="submit"
										variant="defaultHover"
										fontWeight="semibold"
										isDisabled={!isValid || isSubmitSuccessful}
									>
										Salvar
									</Button>
								</Flex>
							</Stack>
						</Box>
					</form>
				</FormProvider>
			</Stack>
		</FullPageLayout>
	)
}
