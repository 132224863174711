import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Text,
} from '@chakra-ui/react'

import { TextGradient } from 'modules/common/components'

import { goalsDictionary } from '../../helpers/goals-dictionary.js'

const AccordionDetails = () => {
	return (
		<Accordion pb={8} mt={4}>
			<Accordion allowMultiple>
				{goalsDictionary?.map((element) => {
					return (
						<AccordionItem key={element.id} pb={2} pt={2}>
							<h2>
								<AccordionButton backgroundColor="#0000000D">
									<Box
										as="span"
										flex="1"
										textAlign="left"
										height="fit-content"
										minHeight="100px"
										display="flex"
										alignItems="center"
									>
										<TextGradient
											fromColor="esmeralda.100"
											toColor="marinho.100"
											fontSize="2xl"
											fontWeight="bold"
										>
											{element.title + ' - ' + element.subtitle}
										</TextGradient>
									</Box>
									<AccordionIcon color="esmeralda.100" fontSize="2.5rem" />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Box
									bg="#035677"
									borderRadius="40px"
									w="800px"
									minHeight="150px"
									height="fit-content"
									mt={8}
									mb={8}
									ml={24}
								>
									<Text fontSize="1xl" color="white" margin={8} mt={2} height="fit-content">
										{element.text}
									</Text>
								</Box>
								<Accordion pb={8} mt={4}>
									<Accordion allowMultiple>
										{element?.ind?.map((indicador) => {
											return (
												<AccordionItem key={indicador.titleInd} pb={2}>
													<h2>
														<AccordionButton backgroundColor="#0000000D">
															<Box
																as="span"
																flex="1"
																textAlign="left"
																minHeight="50px"
																display="flex"
																alignItems="center"
																height="fit-content"
															>
																<TextGradient
																	fromColor="esmeralda.100"
																	toColor="marinho.100"
																	fontSize="1xl"
																	fontWeight="bold"
																>
																	{indicador.titleInd}
																</TextGradient>
															</Box>
															<AccordionIcon color="esmeralda.100" fontSize="2.5rem" />
														</AccordionButton>
													</h2>
													<AccordionPanel pb={4}>
														<Text fontSize="1xl" mb={2} mt={2}>
															{indicador?.body?.body}
														</Text>
														<Text color="esmeralda.100" fontWeight="bold" fontSize="1xl" mb={4}>
															Fórmula
														</Text>
														<Box
															bg="#035677"
															borderRadius="40px"
															w="800px"
															h="150px"
															ml={24}
															justifyContent="center"
															alignItems="center"
															display="flex"
														>
															<Text fontSize="4xl" color="white" margin={8} mt={2}>
																{indicador?.form?.formAll}
															</Text>
														</Box>
														<Text fontWeight="bold" mt={8}>
															variáveis:{' '}
														</Text>
														<Text>{indicador?.form?.a}</Text>
														<Text>{indicador?.form?.b}</Text>
														<Text color="esmeralda.100" mt={8} mb={4} fontWeight="bold">
															Fontes
														</Text>
														{indicador?.body?.font?.map((font) => {
															return (
																<div key={font.link}>
																	<Text fontWeight="bold">{font.fontDes}</Text>
																	{/* <Text ml={2}>{font.link}</Text> */}
																</div>
															)
														})}

														{/* <Text ml={8}>
															* Mortalidade:
															https://datasus.saude.gov.br/mortalidade-desde-1996-pela-cid-10
														</Text>
														<Text fontWeight="bold"> IBGE: </Text>
														<Text ml={8}>
															* Estimativas populacionais por município:
															https://ftp.ibge.gov.br/Estimativas_de_Populacao
														</Text>
														<Text ml={8}>
															{' '}
															* Censo demográfico: https://sidra.ibge.gov.br/tabela/200
														</Text> */}

														<Text color="esmeralda.100" mt={8} mb={4} fontWeight="bold">
															Metodologia
														</Text>
														<Text>{indicador?.body?.body}</Text>
														{/* <Text color="esmeralda.100" mt={8} mb={4} fontWeight="bold">
															ETL
														</Text>
														<Text>
															Pré processamento:
															https://github.com/aiboxlab-pne/dados/tree/main/Scripts/ETL/Populacao/metodo-tceduca/notebook
														</Text>
														<Text>Desagregação: https://pypi.org/project/popro/</Text>
														<Text color="esmeralda.100" mt={8} mb={4} fontWeight="bold">
															Filtro
														</Text>
														<Text>FAIXAS_ETARIAS_ID = 2 (4 a 5 anos)</Text>
														<Text>pop4_5E - População matriculada de 4 a 5 anos</Text>
														<Text color="esmeralda.100" mt={8} mb={4} fontWeight="bold">
															Referências
														</Text>
														<Text>
															[1] - Metodologia de Desagregação Populacional-TCeduca - url:
															https://www.tcesc.tc.br/sites/default/files/2021-06/Metodologia%20Estima%C3%A7%C3%A3o%20Populacional.pdf
														</Text> */}
													</AccordionPanel>
												</AccordionItem>
											)
										})}
									</Accordion>
								</Accordion>
							</AccordionPanel>
						</AccordionItem>
					)
				})}
			</Accordion>
		</Accordion>
	)
}
export default AccordionDetails
