import fetch from 'config/api'

export const getUserInfo = async () => {
	return fetch({
		url: '/usuario/user-info/',
		method: 'GET',
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('user info error', err))
}
