import { useEffect } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { useTable, usePagination } from 'react-table'

import { Table, useBreakpointValue, Flex, Text, Tooltip, IconButton } from '@chakra-ui/react'

import { DesktopTableContent, MobileTableContent } from 'modules/common/components/CustomTable'
import { paginationInfo } from 'modules/common/utils'

export const GSATable = ({
	data,
	columns,
	pagination,
	fetchData,
	currentPage,
	handleChange,
	handleChangeTrash,
	...rest
}) => {
	const mobileScreen = useBreakpointValue({ base: true, md: false })
	const ResponsiveContent = mobileScreen ? MobileTableContent : DesktopTableContent

	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		page,
		headers,
		canNextPage,
		canPreviousPage,
		nextPage,
		previousPage,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			manualPagination: true,
			pageCount: Math.round(pagination.totalElements / 10),
			autoResetPage: false,
			initialState: { pageIndex: currentPage },
		},
		usePagination,
	)

	const info = paginationInfo(pageIndex, pagination)

	useEffect(() => {
		fetchData(pageIndex)
	}, [fetchData, pageIndex])

	return (
		<>
			<Flex w="full" justifyContent="end" alignItems="baseline">
				<Flex alignItems="center">
					<Text fontSize="md" fontWeight="medium" color="gray.900">
						{info.pageIndex}-{info.pageSize} de {info.pageTotal}
					</Text>

					<Flex>
						<Tooltip label="página anterior">
							<IconButton
								aria-label="previous-page-button"
								fontSize="2xl"
								variant="ghost"
								color="gray.900"
								onClick={previousPage}
								icon={<BiChevronLeft />}
								isDisabled={!canPreviousPage}
								_disabled={{ color: 'gray.400', cursor: 'not-allowed' }}
							/>
						</Tooltip>
					</Flex>

					<Flex>
						<Tooltip label="próxima página">
							<IconButton
								aria-label="next-page-button"
								fontSize="2xl"
								variant="ghost"
								color="gray.900"
								onClick={nextPage}
								icon={<BiChevronRight />}
								isDisabled={!canNextPage}
								_disabled={{ color: 'gray.400', cursor: 'not-allowed' }}
							/>
						</Tooltip>
					</Flex>
				</Flex>
			</Flex>

			<Table {...getTableProps()} variant="simple" {...rest}>
				<ResponsiveContent
					headers={headers}
					page={page}
					getTableBodyProps={getTableBodyProps}
					prepareRow={prepareRow}
					handleChange={handleChange}
					handleChangeTrash={handleChangeTrash}
				/>
			</Table>
		</>
	)
}
