import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Text,
} from '@chakra-ui/react'

import { TextGradient } from 'modules/common/components'

import { goalsDictionary } from '../../helpers/goals-dictionary.js'
import DictionaryTable from '../DictionaryTable'

const AccordionDicionary = () => {
	return (
		<Accordion pb={12}>
			<Accordion allowMultiple>
				{goalsDictionary?.map((meta) => {
					return (
						<AccordionItem key={meta.id} pb={2} pt={2}>
							<h2>
								<AccordionButton backgroundColor="#0000000D">
									<Box
										as="span"
										flex="1"
										textAlign="left"
										height="100px"
										display="flex"
										alignItems="center"
									>
										<TextGradient
											fromColor="esmeralda.100"
											toColor="marinho.100"
											fontSize="2xl"
											fontWeight="bold"
										>
											{meta.title + ' - ' + meta.subtitle}
										</TextGradient>
									</Box>
									<AccordionIcon color="esmeralda.100" fontSize="2.5rem" />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Text fontSize="2xl" mb={4} color="#035677" mt={4}>
									{meta.text}
								</Text>
								<DictionaryTable table={meta} />
							</AccordionPanel>
						</AccordionItem>
					)
				})}
			</Accordion>
		</Accordion>
	)
}
export default AccordionDicionary
