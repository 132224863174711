export const addAuthentication = (config) => {
	const {
		state: { accessToken },
	} = JSON.parse(sessionStorage.getItem('user-store'))

	if (accessToken) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${accessToken}`,
		}
	}

	return config
}
