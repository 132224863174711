export const formatFileData = ({ name, size }) => {
	const sizeMb = `${Math.round((size / (1024 * 1024)) * 100) / 100} MB`
	const date = new Date().toLocaleDateString('pt-BR')

	return {
		nome: name,
		tamanho: sizeMb,
		data_de_envio: date,
	}
}
