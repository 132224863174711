import fetch from 'config/api'

export const getUserByCpf = async (cpf) => {
	return fetch({
		url: `/usuario/info-user/${cpf}`,
		method: 'GET',
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => console.error('user info error', err))
}
