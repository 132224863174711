export const profileOptions = [
	{
		id: 1,
		title: 'Super Admin',
		value: 1,
	},
	{
		id: 2,
		title: 'MEC',
		value: 2,
	},
	{
		id: 3,
		title: 'Gestor',
		value: 3,
	},
	{
		id: 4,
		title: 'Articulador',
		value: 4,
	},
	{
		id: 5,
		title: 'Monitoramento',
		value: 5,
	},
	{
		id: 6,
		title: 'Avaliação',
		value: 6,
	},
]

export const statusOptions = [
	{
		id: 1,
		title: 'Ativo',
		value: true,
	},
	{
		id: 2,
		title: 'Inativo',
		value: false,
	},
]
