export const paginationInfo = (pageIndex, pagination) => {
	const { first, last, pageSize, totalElements } = pagination

	const hasOnlyOnePage = {
		pageIndex: pageIndex + 1,
		pageSize: totalElements,
		pageTotal: totalElements,
	}

	const firstPage = {
		pageIndex: pageIndex + 1,
		pageSize,
		pageTotal: totalElements,
	}

	const lastPage = {
		pageIndex: pageIndex * pageSize + 1,
		pageSize: totalElements,
		pageTotal: totalElements,
	}

	const pageInTheMiddle = {
		pageIndex: pageIndex * pageSize + 1,
		pageSize: (pageIndex + 1) * pageSize,
		pageTotal: totalElements,
	}

	if (first && last) {
		return hasOnlyOnePage
	}

	if (first && !last) {
		return firstPage
	}

	if (last && !first) {
		return lastPage
	}

	return pageInTheMiddle
}
