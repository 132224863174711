import fetch from 'config/api'

export const createArticulator = async (data) => {
	return fetch({
		url: '/perfil/',
		method: 'POST',
		data,
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => err.response)
}
