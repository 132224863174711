import { Box, Stack, Text } from '@chakra-ui/react'

import { TextGradient } from '..'

import { userStore } from 'store/user'

export const ProgressLine = () => {
	const { pathname } = window.location
	const activeStatus = { active: true, bg: 'esmeralda.100' }
	const inactiveStatus = { active: false, bg: 'white' }

	const isOnArticulators = pathname.includes('/articulators') ? activeStatus : inactiveStatus
	const isOnEducationPlan = pathname.includes('/plano-de-educacao') ? activeStatus : inactiveStatus
	const isOnGoals = pathname.includes('/metas-estrategias-acoes') ? activeStatus : inactiveStatus

	return (
		<Stack w="full" mt={[8, -8]} pb={8} justify={['start', 'center']} direction={['row', 'column']}>
			<Stack
				top={['0', '64px']}
				left={['16px', '0']}
				position="relative"
				direction={['column', 'row']}
				justify="space-evenly"
				align={['flex-end', 'baseline']}
			>
				<ArticulatorsStep isOnArticulators={isOnArticulators} />
				<Stack direction={['row', 'column']} align="center">
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontWeight="bold"
						fontSize="xl"
					>
						Plano de Educação
					</TextGradient>
					<Box
						w="15px"
						h="15px"
						bg={isOnEducationPlan.bg}
						border="2px solid #3F9E6E"
						rounded={50}
					/>
					{isOnEducationPlan.active && (
						<Text color="esmeralda.100" fontWeight="semibold">
							você está aqui
						</Text>
					)}
				</Stack>
				<Stack direction={['row', 'column']} align="center">
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontWeight="bold"
						fontSize="xl"
					>
						Metas/Estratégias/Ações
					</TextGradient>
					<Box w="15px" h="15px" bg={isOnGoals.bg} border="2px solid #3F9E6E" rounded={50} />
					{isOnGoals.active && (
						<Text color="esmeralda.100" fontWeight="semibold">
							você está aqui
						</Text>
					)}
				</Stack>
			</Stack>
			<Stack border="1px solid #A8A1A1" />
		</Stack>
	)
}

const ArticulatorsStep = ({ isOnArticulators }) => {
	const user = userStore((state) => state.user)
	const isArticulator = user.pflcod === '0002'

	if (isArticulator) return

	return (
		<Stack direction={['row', 'column']} align="center">
			<TextGradient fromColor="esmeralda.100" toColor="marinho.100" fontWeight="bold" fontSize="xl">
				Articuladores
			</TextGradient>
			<Box w="15px" h="15px" bg={isOnArticulators.bg} border="2px solid #3F9E6E" rounded={50} />
			{isOnArticulators.active && (
				<Text color="esmeralda.100" fontWeight="semibold">
					você está aqui
				</Text>
			)}
		</Stack>
	)
}
