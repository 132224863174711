import { useContext } from 'react'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

import { ThemeContext } from 'theme'
import { Fonts } from 'theme/fonts'

import Router from './routes'

const App = () => {
	const { theme } = useContext(ThemeContext)

	return (
		<ChakraProvider theme={theme}>
			<ColorModeScript />
			<Fonts />
			<Router />
		</ChakraProvider>
	)
}

export default App
