/* eslint-disable react/display-name */
import React from 'react'

import { Box, Text, Button } from '@chakra-ui/react'

import { TextGradient } from 'modules/common/components'
import { FullPageLayout } from 'modules/common/components/FullPageLayout'

export const HowConectaPneWorks = React.forwardRef((props, ref) => {
	return (
		<FullPageLayout shadow="md">
			<Box ref={ref} py={8}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize="3xl"
					fontWeight="bold"
				>
					Como funciona a Conecta PNE?
				</TextGradient>

				<Text py={6} fontSize="lg" fontWeight="normal" aria-label="Sobre a Plataforma Conecta PNE">
					A Plataforma Conecta PNE permite a visualização de dados e insights relativos à evolução
					do PNE e dos planos subnacionais de educação, conectando-se com diversas bases de dados
					abertos e fornecendo informações de forma objetiva e inteligente, podendo ser utilizada
					por todos os cidadãos brasileiros. Por meio dela, é possível, para gestores municipais
					e/ou estaduais, cadastrarem as especificidades de seus planos de educação, monitorarem o
					progresso dos planos e realizarem as avaliações necessárias, para tomada de decisões e
					ajustes ao longo dos anos. Além disso, para outros profissionais que precisem de acesso a
					estes dados, a plataforma conta com filtros inteligentes e personalizáveis, e mostra a
					evolução dos planos em qualquer ente federado do país de forma transparente e objetiva
					para qualquer cidadão que deseje obter essas informações.
				</Text>

				<Box display="flex" justifyContent="flex-end">
					<Button
						p={5}
						variant="gradient"
						aria-label="Entenda o passo a passo"
						onClick={() => props.stepsRef.current.scrollIntoView({ behavior: 'smooth' })}
					>
						<Text p={4} fontSize="xl" fontWeight="bold">
							Entenda o passo a passo
						</Text>
					</Button>
				</Box>
			</Box>
		</FullPageLayout>
	)
})
