import { useMemo, useState, useCallback } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { GSATable } from 'modules/goals-strategies-actions/components'

import { deleteStrategy } from './usecases'

export const List = () => {
	const [page, setPage] = useState(0)
	const addSelectedStrategy = useBoundStore((state) => state.addSelectedStrategy)
	const removeSelectedStrategy = useBoundStore((state) => state.removeSelectedStrategy)

	const strategies = useBoundStore((state) => state.strategies)

	const columnsStrategy = useMemo(
		() => [
			{
				Header: '',
				accessor: 'checkbox',
			},
			{
				Header: 'Número estratégia',
				accessor: 'numero',
			},
			{
				Header: 'Meta Associada',
				accessor: 'meta_subnacional',
			},
			{
				Header: 'Descrição',
				accessor: 'descricao',
			},
			{
				Header: '',
				accessor: 'trash',
			},
		],
		[],
	)

	if (!strategies.length)
		return (
			<Flex w="full" p={4} mt={10} bg="white" justify="center">
				<Text textAlign="center">Nenhuma estratégia cadastrada até o momento</Text>
			</Flex>
		)

	const tableData = strategies?.map((strategy) => ({
		...strategy,
		meta_subnacional: `Meta ${strategy.meta_subnacional.numero}`,
	}))

	const pageSlice = page === 0 ? page : page * 10
	const data = tableData.slice(pageSlice, (page + 1) * 10)

	const handleSelectedStrategy = (event) => {
		event.preventDefault()
		const { checked, value } = event.target

		if (!checked) {
			removeSelectedStrategy(value)
		} else {
			addSelectedStrategy(value)
		}
	}

	const handleChangeTrashStrategy = async (item) => {
		await deleteStrategy(item)
	}

	const pagination = {
		first: page === 0,
		last: (page + 1) * 10 >= tableData.length,
		pageSize: 10,
		totalElements: tableData.length,
	}

	const fetchData = useCallback((index) => {
		setPage(index)
	}, [])

	return (
		<Box mt={8} mb={6}>
			<GSATable
				data={data}
				columns={columnsStrategy}
				pagination={pagination}
				fetchData={fetchData}
				currentPage={page}
				handleChange={handleSelectedStrategy}
				handleChangeTrash={handleChangeTrashStrategy}
				bg="white"
			/>
		</Box>
	)
}
