import { InputField, SelectField } from 'modules/common/components'

import { CpfField } from '../components/cpf-field'
import { profileOptions, statusOptions } from '../helpers'

export const Form = ({ setFoundUser, hideProfileField = false }) => {
	return (
		<>
			<CpfField setFoundUser={setFoundUser} />
			<InputField
				label="Nome"
				fieldName="nome"
				placeholder="Fulano de tal"
				labelProps={{ fontSize: '2xl', fontWeight: 'bold' }}
				display={{ base: 'block', lg: 'flex' }}
				alignItems="center"
				gap={{ base: 0, lg: 14 }}
			/>
			<InputField
				label="Telefone *"
				fieldName="celular"
				placeholder="(DDD) 00000.0000"
				labelProps={{ fontSize: '2xl', fontWeight: 'bold' }}
				display={{ base: 'block', lg: 'flex' }}
				alignItems="center"
				gap={{ base: 0, lg: 2 }}
			/>
			<InputField
				label="E-mail *"
				fieldName="email"
				placeholder="Escreva seu e-mail"
				labelProps={{ fontSize: '2xl', fontWeight: 'bold' }}
				display={{ base: 'block', lg: 'flex' }}
				alignItems="center"
				gap={{ base: 0, lg: 8 }}
			/>
			{!hideProfileField && (
				<SelectField
					label="Perfil *"
					fieldName="perfil_do_usuario"
					placeholder="Selecione um perfil"
					options={profileOptions}
					labelProps={{ fontSize: '2xl', fontWeight: 'bold' }}
					display={{ base: 'block', lg: 'flex' }}
					alignItems="center"
					gap={{ base: 0, lg: 10 }}
				/>
			)}
			<SelectField
				label="Status *"
				fieldName="active"
				placeholder="Selecione um status"
				options={statusOptions}
				labelProps={{ fontSize: '2xl', fontWeight: 'bold' }}
				display={{ base: 'block', lg: 'flex' }}
				alignItems="center"
				gap={{ base: 0, lg: 8 }}
			/>
		</>
	)
}
