import { object, array, string } from 'yup'

export const ActionsValidation = () =>
	object({
		actionNumber: string()
			.required('Campo obrigatório')
			.matches(/^[0-9]+$/, 'Preencha apenas com números'),
		actionDescription: string().required('Campo obrigatório'),
		strategies: array()
			.required('Campo obrigatório')
			.test({
				test: (array) => array.length > 0,
				message: 'Selecione pelo menos 1 estratégia',
			}),
	})
