import { Stack, Text } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { InputField, TextareaField } from 'modules/common/components'

import { IndicatorFieldArray, CheckboxField } from './components'

export const Form = () => {
	const nationalGoals = useBoundStore((state) => state.nationalGoals)

	return (
		<Stack spacing={4}>
			<Text color="gray.900" fontSize="lg" fontWeight="bold">
				Informe o número e descreva a meta do Plano Municipal de Educação
			</Text>
			<InputField
				label="Número*"
				fieldName="goalNumber"
				placeholder="Ex: 1.200"
				w="fit-content"
				display="flex"
				alignItems="center"
				gap={{ base: 0, md: 8 }}
			/>

			<TextareaField
				label="Descrição*"
				fieldName="goalDescription"
				placeholder="Exemplo: Universalizar, até 2016, a educação infantil na pré-escola para as crianças de 4 (quatro) a 5 (cinco) anos de idade e ampliar a oferta de educação infantil em creches de forma a atender, no mínimo, 50% (cinquenta por cento) das crianças de até 3 (três) anos até o final da vigência deste PNE."
				backgroundColor="white"
				width="75%"
			/>

			<Text color="gray.900" fontSize="lg" fontWeight="bold">
				A meta descrita acima está relacionada a quais metas do Plano Nacional de Educação - PNE -
				Lei 13.005/2014?
			</Text>
			{nationalGoals && <CheckboxField groupName="nationalGoals" options={nationalGoals} />}

			<IndicatorFieldArray />
		</Stack>
	)
}
