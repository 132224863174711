import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
} from '@chakra-ui/react'

import { TextGradient } from 'modules/common/components'

const AccordionData = () => {
	return (
		<Accordion pb={8}>
			<Accordion allowMultiple>
				<AccordionItem pb={2}>
					<h2>
						<AccordionButton backgroundColor="#0000000D">
							<Box
								as="span"
								flex="1"
								textAlign="left"
								height="100px"
								display="flex"
								alignItems="center"
							>
								<TextGradient
									fromColor="esmeralda.100"
									toColor="marinho.100"
									fontSize="2xl"
									fontWeight="bold"
								>
									Como faço para carregar e abrir um arquivo .paquet?
								</TextGradient>
							</Box>
							<AccordionIcon color="esmeralda.100" fontSize="2.5rem" />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					</AccordionPanel>
				</AccordionItem>

				<AccordionItem pb={2}>
					<h2>
						<AccordionButton backgroundColor="#0000000D">
							<Box
								as="span"
								flex="1"
								textAlign="left"
								height="100px"
								display="flex"
								alignItems="center"
							>
								<TextGradient
									fromColor="esmeralda.100"
									toColor="marinho.100"
									fontSize="2xl"
									fontWeight="bold"
								>
									Como faço para carregar e abrir um arquivo .paquet?
								</TextGradient>
							</Box>
							<AccordionIcon color="esmeralda.100" fontSize="2.5rem" />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</Accordion>
	)
}
export default AccordionData
