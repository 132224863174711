import { object, string } from 'yup'

export const ProfileValidation = () =>
	object({
		cpf: string()
			.required('Campo obrigatório')
			.matches(/^[0-9]+$/, 'Preencha apenas com números')
			.min(11, 'CPF precisa ter 11 dígitos')
			.max(11, 'CPF precisa ter 11 dígitos'),
		nome: string().required('Campo obrigatório'),
		celular: string().required('Campo obrigatório'),
		email: string().email('Insira um e-mail válido').required('Campo obrigatório'),
		perfil_do_usuario: string().required('Campo obrigatório'),
		active: string().required('Campo obrigatório'),
	})
