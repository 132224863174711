import { useMemo, useState, useCallback } from 'react'

import { Flex, Box, Text } from '@chakra-ui/react'

import { useBoundStore } from 'store'

import { GSATable } from 'modules/goals-strategies-actions/components'

import { deleteGoal } from './usecases'

export const List = () => {
	const goals = useBoundStore((state) => state.goals)
	const setSelectedGoal = useBoundStore((state) => state.setSelectedGoal)
	const [page, setPage] = useState(0)

	const pageSlice = page === 0 ? page : page * 10
	const tableData = goals.slice(pageSlice, (page + 1) * 10)

	const handleSelectedGoal = (event) => {
		event.preventDefault()
		const { checked, value } = event.target

		if (!checked) {
			setSelectedGoal(null)
		} else {
			setSelectedGoal(value)
		}
	}

	const handleChangeTrashGoals = async (item) => {
		await deleteGoal(item)
	}

	const columnsGoals = useMemo(
		() => [
			{
				Header: '',
				accessor: 'checkbox',
			},
			{
				Header: 'Número Meta',
				accessor: 'numero',
			},
			{
				Header: 'Meta Relacionada',
				accessor: 'meta_nacional',
			},
			{
				Header: 'Descrição',
				accessor: 'descricao',
			},
			{
				Header: '',
				accessor: 'trash',
			},
		],
		[],
	)

	if (!goals.length)
		return (
			<Flex w="full" p={4} mt={10} bg="white" justify="center">
				<Text textAlign="center">Nenhuma meta cadastrada até o momento</Text>
			</Flex>
		)

	const pagination = {
		first: page === 0,
		last: (page + 1) * 10 >= goals.length,
		pageSize: 10,
		totalElements: goals.length,
	}

	const fetchData = useCallback((index) => {
		setPage(index)
	}, [])

	return (
		<Box mt={8} mb={6}>
			<GSATable
				data={tableData}
				columns={columnsGoals}
				pagination={pagination}
				fetchData={fetchData}
				currentPage={page}
				bg="white"
				handleChange={handleSelectedGoal}
				handleChangeTrash={handleChangeTrashGoals}
			/>
		</Box>
	)
}
